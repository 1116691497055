<template>
  <div>
    <div v-if="!userInfo || !userInfo.admin">
      YOU ARE NOT AN ADMIN!
    </div>
    <div v-if="userInfo && userInfo.admin">
      <newProject class="ma-3" />
      <div v-if="all_projects.length > 0">
        <div v-for="(project, index) in all_projects" :key="index" class="ma-1">
          <oneProject :project="project" @remove="remove" :key="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

import newProject from "@/components/modals/newProject";
import oneProject from "@/components/modals/oneProject";

export default {
  data: () => ({}),
  async created() {
    await this.loadProjects();
  },
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
  },
  methods: {
    remove() {
      this.all_projects = [];
      this.loadProjects();
    },
  },
  components: {
    newProject,
    oneProject,
  },
  mixins: [loadGlobalsMixin],
};
</script>
<style>
</style>