<template>
  <div>
    <div><h1>All Components</h1></div>
    <selectComponent
      @selectedComponentFunc="selectedComponentFunc"
      :justSelect="true"
    />
    <div v-if="filteredComponents.length > 0">
      <allMyComponentsTable
        :items="items"
        :headers="headers"
        :components="filteredComponents"
      />
    </div>
  </div>
</template>

<script>
import fire from "@/main";

import selectComponent from "@/components/PCB/componentSketches/selectComponent";
import allMyComponentsTable from "./allMyComponentsTable";
export default {
  // props: {
  //   allFields: null,
  // },
  data: () => ({
    selectedComponent: null,
    allComponents: null,
  }),
  computed: {
    filteredComponents() {
      let res = [];
      if (this.allComponents && this.selectedComponent) {
        for (const oneComponentKey in this.allComponents) {
          if (Object.hasOwnProperty.call(this.allComponents, oneComponentKey)) {
            const oneComponent = this.allComponents[oneComponentKey];
            if (oneComponent.component === this.selectedComponent.value) {
              res.push(oneComponent);
              // console.log("oneComponent", JSON.stringify(oneComponent));
            }
          }
        }
      }
      return res;
    },
    headers() {
      if (this.filteredComponents.length > 0) {
        let output = [];
        output.push({ text: "id", value: "id", align: "start" });
        for (const element of this.filteredComponents) {
          output = output.concat(this.processFields(element.result));
        }
        output = output.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((o) => o.text === obj.text && o.value === obj.value)
        );

        return output;
      }
      return [];
    },
    items() {
      if (this.filteredComponents.length > 0) {
        let output = [];
        for (const element of this.filteredComponents) {
          let tmp = this.processFields2(element.result);
          tmp.push({ id: element.id });
          let object = {};
          for (let i = 0; i < tmp.length; i++) {
            let key = Object.keys(tmp[i])[0];
            object[key] = tmp[i][key];
          }
          output.push(object);
        }
        // output = output.filter(
        //   (obj, index, self) =>
        //     index ===
        //     self.findIndex((o) => o.text === obj.text && o.value === obj.value)
        // );
        console.log("output", output);
        return output;
        //   return this.filteredComponents.map((component) => {
        //     const item = {};
        //     component.fields.forEach((field) => {
        //       item[field.label] = field.value;
        //     });
        //     item["id"] = component.id;
        //     return item;
        //   });
      }
      return [];
    },
  },
  async created() {
    let viewMessage = this;
    let itemsRef = await fire.database().ref(`PCB/myComponents/`);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.allComponents = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        }));
      }
    });
  },
  methods: {
    processFields(fields) {
      let result = [];
      if (fields["fields"]) {
        fields = fields["fields"];
        for (const field in fields) {
          if (fields[field].myValue) {
            if (fields[field].label != "component") {
              result.push({
                text: fields[field].label,
                value: fields[field].label,
              });
            }
          }
          if (fields[field].options) {
            for (const option in fields[field].options) {
              result = result.concat(
                this.processFields(fields[field].options[option])
              );
            }
          }
        }
      }
      return result;
    },
    processFields2(fields) {
      let result = [];
      if (fields["fields"]) {
        fields = fields["fields"];
        for (const field in fields) {
          if (fields[field].myValue) {
            let res_tmp = {};
            res_tmp[fields[field].label] = fields[field].myValue;
            result.push(
              res_tmp
              // text: fields[field].label,
              // value: fields[field].label,
            );
          }
          if (fields[field].options) {
            for (const option in fields[field].options) {
              result = result.concat(
                this.processFields2(fields[field].options[option])
              );
            }
          }
        }
      }
      return result;
    },
    selectedComponentFunc(payload) {
      this.selectedComponent = payload;
    },
  },
  components: {
    selectComponent,
    allMyComponentsTable,
  },
};
</script>

<style>
</style>

