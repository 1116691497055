<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      v-if="project"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text x-large block color="primary" v-bind="attrs" v-on="on">
          {{ project.name }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark text @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn dark icon @click="remove">
            <v-icon color="error">mdi-delete-forever</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> {{ project.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="Project Name"
            single-line
            v-model="project.name"
          ></v-text-field>
          <div>
            <newUserToProject
              :project="project"
              :usersInProject="usersInProject"
            />
          </div>
          <div v-if="project.per_hour" class="mt-3">
            users in project:
            <div
              v-for="userInProject in usersInProject"
              :key="userInProject.id"
            >
              <oneUserInProject
                class="ma-1"
                :user="userInProject"
                :project="project"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

import newUserToProject from "@/components/modals/newUserToProject";

import oneUserInProject from "@/components/modals/oneUserInProject";

export default {
  props: {
    project: null,
  },
  data: () => ({
    dialog: false,
  }),
  components: {
    newUserToProject,
    oneUserInProject,
  },
  async created() {
    await this.loadUsers();
  },
  computed: {
    usersInProject() {
      let arr = [];
      if (this.project.per_hour) {
        let arr_tmp = Object.keys(this.project.per_hour).map((key) => ({
          ...this.project.per_hour[key],
          id: key,
        }));
        arr = [...arr, ...arr_tmp];
        for (const userInProject in arr) {
          if (Object.hasOwnProperty.call(arr, userInProject)) {
            const element = arr[userInProject];
            let user_tmp = null;
            user_tmp = this.all_users.filter((u) => {
              if (u.id === element.uid) {
                return u;
              }
            });
            if (user_tmp.length > 0) {
              element.name = user_tmp[0].info.name;
              element.last_selected_project = user_tmp[0].last_selected_project;
            }
          }
        }
      }
      return arr;
    },
  },
  methods: {
    async save() {
      await fire
        .database()
        .ref(`projects/${this.project.id}/`)
        .update({ name: this.project.name });
      this.dialog = false;
    },
    async remove() {
      await fire.database().ref(`projects/${this.project.id}/`).set(null);
      this.dialog = false;
      this.$emit("remove");
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>