<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <!-- <v-list-item-title>edit</v-list-item-title>
          <v-spacer></v-spacer> -->
          <v-icon color="grey lighten-1">mdi-cog</v-icon>
        </v-list-item>
        <!-- <v-btn color="primary" v-bind="attrs" v-on="on"> EDIT RECORD </v-btn> -->
      </template>
      <v-card v-if="!loading_tmp">
        <v-toolbar dark color="primary">
          <v-btn dark text @click="save"> SAVE </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> EDIT RECORD</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-textarea solo rows="2" v-model="record.text"></v-textarea>
          <v-card flat>
            <v-card-title class="pa-1 mb-3">
              current groups:
              <v-spacer></v-spacer>
              <addGroup
                :record="record"
                :allGroupTypes="group_types"
                :allGroups="allGroups"
              />
            </v-card-title>
            <v-card-text>
              <v-card
                class="ma-0 pa-0 ml-3"
                flat
                v-for="group in record.groups"
                :key="group.id"
              >
                <v-card-title class="ma-0 pa-0">
                  <span
                    @click="goToGroupType(groupNameByID(group.group_id).typeID)"
                    class="subtitle-1 text--secondary"
                    >{{ groupNameByID(group.group_id).type }}/
                  </span>
                  <span class="mx-2">
                    {{ groupNameByID(group.group_id).name }}</span
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="record.groups.length > 1"
                    icon
                    @click="deleteGroup(group.id)"
                  >
                    <v-icon color="red">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
          <v-divider class="my-3"></v-divider>
          <div>
            <span>created: {{ record.created }}</span>
          </div>
          <div>
            <span>last modified: {{ record.last_modified }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import addGroup from "@/components/todo/modals/addGroup";

export default {
  props: {
    record: null,
    group_types: null,
  },
  data: () => ({
    loading_tmp: true,
    dialog: false,
    allGroups: null,
    allGroupTypes: null,
  }),
  computed: {
    ...mapGetters(["UID"]),
  },
  async created() {
    this.loading_tmp = true;
    let viewMessage = this;
    let itemsRef = await fire.database().ref(`todo/users/${this.UID}/groups/`);
    await itemsRef.on("value", async (snapshot) => {
      viewMessage.loading_tmp = true;
      let data = snapshot.val();
      if (data) {
        viewMessage.allGroups = Object.keys(data).map((key) => {
          let arr = {
            ...data[key],
          };
          let new_arr = {};
          new_arr.id = key;
          new_arr.groups = [];
          for (const key in arr) {
            if (Object.hasOwnProperty.call(arr, key)) {
              const element = arr[key];
              element.id = key;
              new_arr.groups.push(element);
            }
          }
          return new_arr;
        });
        viewMessage.loading_tmp = false;
      }
    });
    let itemsRef2 = await fire
      .database()
      .ref(`todo/users/${this.UID}/group_types/`);
    await itemsRef2.on("value", async (snapshot) => {
      viewMessage.loading_tmp = true;
      let data2 = snapshot.val();
      if (data2) {
        this.allGroupTypes = Object.keys(data2).map((key) => ({
          ...data2[key],
          id: key,
        }));
        viewMessage.loading_tmp = false;
      }
    });
  },
  methods: {
    groupNameByID(id) {
      // console.log("groupNameByID", this.group_types);
      if (this.group_types) {
        let group_tmp = null;
        let groupType = null;
        this.group_types.forEach((gt) => {
          if (gt.groups) {
            if (gt.groups.value) {
              let res_tmp = gt.groups.value.find((g) => g.id === id);
              if (res_tmp) {
                group_tmp = res_tmp;
                groupType = gt;
              }
            }
          }
        });
        return {
          type: groupType.name,
          name: group_tmp.name,
          typeID: groupType.id,
        };
      }
      return id;
    },
    goToGroupType(id) {
      // console.log("editRecord goToGroupType", id);
      this.$emit("groupTypeSelected", id);
      this.dialog=false
    },
    async save() {
      console.log(this.record);
      await fire
        .database()
        .ref(`todo/users/${this.UID}/records/${this.record.id}/`)
        .update({ text: this.record.text, last_modified: Date() });
      this.dialog = false;
    },
    async deleteGroup(id) {
      await fire
        .database()
        .ref(`todo/users/${this.UID}/records/${this.record.id}/groups/${id}`)
        .set(null);
    },
  },
  components: {
    addGroup,
  },
};
</script>

<style>
</style>