<template>
  <div v-if="allProjects" class="ma-3">
    <v-select
      class="ma-3 pa-3"
      v-if="allGroups.length > 0"
      v-model="selectedGroup"
      :hint="'select group'"
      :items="allGroups"
      item-text="name"
      item-value="id"
      :label="'select group'"
      persistent-hint
      return-object
      single-line
    ></v-select>
    <allProjectRecords
      :key="update"
      v-if="selectedGroup"
      :allProjects="allProjects_computed"
    />
  </div>
</template>

<script>
import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";
import allProjectRecords from "@/components/records/allProjectRecords";
export default {
  props: {
    allProjects: null,
    allGroups: null,
  },
  data: () => ({
    selectedGroup: null,
    update: false,
  }),
  computed: {
    allProjects_computed() {
      if (this.selectedGroup) {
        if (this.selectedGroup.projects) {
          let all_projects = Object.keys(this.selectedGroup.projects).map(
            (key) => ({
              ...this.selectedGroup.projects[key],
              id: key,
            })
          );
          let projects_array = [];
          all_projects.filter((p) => {
            if (p.uid === this.UID) {
              projects_array.push(p.value);
              return p;
            }
          });
          let ret = this.allProjects.filter((one_project) => {
            if (projects_array.indexOf(one_project.id) > -1) {
              return one_project;
            }
          });
          return ret;
        }
        return [];
      }
      return [];
    },
  },
  created() {
    // if (this.allProjects) {
    //   this.allProjectsSum = {};
    //   this.allProjects.forEach((proj) => {
    //     this.allProjectsSum[proj.id] = {};
    //     this.allProjectsSum[proj.id].sum = 0;
    //     this.allProjectsSum[proj.id].open = false;
    //   });
    // }
  },
  methods: {},
  components: {
    allProjectRecords,
  },
  watch: {
    selectedGroup() {
      this.update = !this.update;
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>