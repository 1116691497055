<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> add user </v-btn>
      </template>
      <v-card :loading="loading_tmp">
        <v-toolbar dark color="primary">
          <v-btn v-if="!loading_tmp" dark text @click="add"> ADD </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> Add User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-select
            v-model="user"
            :hint="'select user'"
            :items="usersToShow"
            item-text="info.name"
            item-value="id"
            :label="'select user'"
            persistent-hint
            return-object
            single-line
          ></v-select>
          <v-text-field
            type="number"
            label="Hour Payment"
            single-line
            v-model="per_hour"
          ></v-text-field>
          <v-text-field
            type="number"
            label="Arrival Fee"
            single-line
            v-model="arrive_price"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

export default {
  props: {
    project: null,
    usersInProject: null,
  },
  data: () => ({
    loading_tmp: false,
    dialog: false,
    user: null,
    per_hour: null,
    arrive_price: null,
  }),
  computed: {
    usersToShow() {
      let arr = [];
      arr = [...arr, ...this.all_users];
      if (this.usersInProject) {
        if (this.usersInProject.length > 0) {
          // console.log("this.usersInProject", this.usersInProject);
          arr = [];
          for (const key in this.all_users) {
            if (Object.hasOwnProperty.call(this.all_users, key)) {
              const oneUser = this.all_users[key];
              // console.log("oneUser", oneUser);
              let sameUsers = this.usersInProject.filter((sameUser) => {
                // console.log("sameUser", sameUser);
                if (sameUser.uid === oneUser.id) {
                  return sameUser;
                }
              });
              let isNewUser = true;
              // console.log("sameUsers", sameUsers);
              if (sameUsers && sameUsers.length > 0) {
                isNewUser = false;
              }
              if (isNewUser) {
                arr.push(oneUser);
              }
            }
          }
        }
      }
      return arr;
    },
  },
  async created() {
    await this.loadUsers();
  },
  methods: {
    async add() {
      this.loading_tmp = true;
      await fire
        .database()
        .ref(`projects/${this.project.id}/per_hour`)
        .push({ uid: this.user.id, value: this.per_hour });
      await fire
        .database()
        .ref(`projects/${this.project.id}/arrive_price`)
        .push({ uid: this.user.id, value: this.arrive_price });
      await fire
        .database()
        .ref(`projects/${this.project.id}/current_working_state`)
        .push({ uid: this.user.id, value: false });
      await fire
        .database()
        .ref(`projects/${this.project.id}/current_working_from_home`)
        .push({ uid: this.user.id, value: false });
      await fire
        .database()
        .ref(`projects/${this.project.id}/start_timestemp`)
        .push({ uid: this.user.id, value: "" });
      // await fire
      //   .database()
      //   .ref(`projects/${this.project.id}/last_selected_project`)
      //   .push({ uid: this.user.id, value: "" });
      this.loading_tmp = false;
      this.dialog = false;
      // this.$emit("loading")
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>