<template>
  <v-hover v-slot="{ hover }">
    <v-card v-if="record" class="my-2" :elevation="hover ? 7 : 2">
      <v-card-title
        class="py-1 px-0"
        :class="noGroups ? 'blue-grey lighten-5' : ''"
      >
        <v-row no-gutters align="center" justify="center">
          <v-col cols="11">
            <v-btn class="mx-0 px-0" dark icon @click="changeDone(record)">
              <v-icon v-if="done" class="mx-0 px-0" color="grey lighten-1"
                >mdi-checkbox-intermediate</v-icon
              >
              <v-icon v-else class="mx-0 px-0" color="grey lighten-1"
                >mdi-checkbox-blank-outline</v-icon
              >
            </v-btn>
            <span
              class="mx-3 text-h6 text--secondary"
              @click="folded = !folded"
              >{{ shortenMsg.txt }}</span
            >
          </v-col>
          <v-col cols="1" class="text-right">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-0 px-0 float-right"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mx-0 px-0" color="grey lighten-1"
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <editRecord
                  @groupTypeSelected="groupTypeSelected"
                  :record="record"
                  :group_types="group_types"
                />
                <v-list-item @click="remove(record.id)">
                  <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="shortenMsg.toBig && !folded">
        {{ record.text }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import editRecord from "@/components/todo/modals/editRecord";

export default {
  props: {
    record: null,
    group_types: null,
    done: {
      default: false,
    },
  },
  data: () => ({
    folded: true,
    windowWidth: window.screen.width,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
    noGroups() {
      if (this.record) {
        if (this.record.groups) {
          return false;
        }
      }
      return true;
    },
    shortenMsg() {
      this.windowWidth;
      let res = {};
      let i = Math.round(this.windowWidth / 17);
      if (this.record.text.length > i) {
        res.txt = this.record.text.slice(0, i - 3) + "...";
        res.toBig = true;
        return res;
      }
      res.txt = this.record.text;
      res.toBig = false;
      return res;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    groupTypeSelected(id) {
      this.$emit("groupTypeSelected", id);
    },
    onResize() {
      this.windowWidth = window.screen.width;
    },
    async remove(id) {
      await fire
        .database()
        .ref(`todo/users/${this.UID}/records/${id}`)
        .set(null);
    },
    async changeDone(rec) {
      await fire
        .database()
        .ref(`todo/users/${this.UID}/records/${rec.id}`)
        .update({
          done: !rec.done,
        });
    },
    settings() {
      console.log("more");
    },
  },
  components: {
    editRecord,
  },
};
</script>

<style>
</style>