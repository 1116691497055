<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="primary" v-bind="attrs" v-on="on" x-large> new project </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark text @click="create"> CREATE </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> New Project</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="Project Name"
            single-line
            v-model="name"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  data: () => ({
    dialog: false,
    name: null,
  }),
  methods: {
    async create() {
      await fire.database().ref(`projects/`).push({ name: this.name });
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>