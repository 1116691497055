<template>
  <v-card class="mx-3 mb-3" elevation="5">
    <v-card-title>
      <v-tabs color="primary" v-model="tab" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <allComponentSketches />
        </v-tab-item>
        <v-tab-item>
          <componentBuilder />
          <allMyComponents />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import allComponentSketches from "@/components/PCB/componentSketches/allComponents";
import componentBuilder from "@/components/PCB/componentsCreator/componentBuilder.vue";
import allMyComponents from "@/components/PCB/myComponents/allMyComponents";

export default {
  data: () => ({
    tab: null,
    items: ["Component Sketches", "Component Creator"],
  }),
  components: {
    allComponentSketches,
    componentBuilder,
    allMyComponents,
  },
};
</script>

<style>
</style>