<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      v-if="group"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text x-large block color="primary" v-bind="attrs" v-on="on">
          {{ group.name }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark text @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn dark icon @click="remove">
            <v-icon color="error">mdi-delete-forever</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> {{ group.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="Project Name"
            single-line
            v-model="group.name"
          ></v-text-field>
          <div>
            <newProjectToGroup
              :group="group"
              :projectsInGropu="projectsInGropu"
            />
          </div>
          <div v-if="projectsInGropu.length > 0" class="mt-3">
            projects in group:
            <div
              v-for="projectInGropu in projectsInGropu"
              :key="projectInGropu.id"
            >
              <!-- {{ projectInGropu.name }} -->
              <oneProjectInGroup
                class="ma-1"
                :project="projectInGropu"
                :group="group"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

import newProjectToGroup from "@/components/modals/newProjectToGroup";

import oneProjectInGroup from "@/components/modals/oneProjectInGroup";

export default {
  props: {
    group: null,
  },
  data: () => ({
    dialog: false,
  }),
  components: {
    newProjectToGroup,
    oneProjectInGroup,
  },
  async created() {
    await this.loadProjects();
    await this.loadUsers();
  },
  computed: {
    projectsInGropu() {
      let arr = [];
      if (this.group.projects) {
        let arr_tmp = Object.keys(this.group.projects).map((key) => ({
          ...this.group.projects[key],
          id: key,
        }));
        arr = [...arr, ...arr_tmp];
        arr = arr.filter((pr) => {
          // console.log("pr", pr);
          if (pr.uid === this.UID) {
            return pr;
          }
        });
        for (const projectInGropu in arr) {
          if (Object.hasOwnProperty.call(arr, projectInGropu)) {
            const element = arr[projectInGropu];
            let project_tmp = null;
            project_tmp = this.all_projects.filter((p) => {
              if (p.id === element.value) {
                return p;
              }
            });
            if (project_tmp.length > 0) {
              element.name = project_tmp[0].name;
            }
          }
        }
      }
      return arr;
    },
  },
  methods: {
    async save() {
      await fire
        .database()
        .ref(`groups/${this.group.id}/`)
        .update({ name: this.group.name });
      this.dialog = false;
    },
    async remove() {
      await fire.database().ref(`groups/${this.group.id}/`).set(null);
      this.dialog = false;
      this.$emit("remove");
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>