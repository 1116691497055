<template>
  <div>
    <v-card v-if="group_types">
      <v-card-title class="primary white--text">
        GROUPS
        <v-spacer></v-spacer>
        <newGroupType />
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-model="selectedGroupType_num"
          accordion
          hover
          focusable
        >
          <v-expansion-panel
            v-for="group_type in group_types"
            :key="group_type.id"
          >
            <v-expansion-panel-header hide-actions v-slot="{ open }">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="11">
                  {{ group_type.name }}
                </v-col>
                <v-col cols="1" v-if="open">
                  <newGroup :toGroup="group_type" />
                </v-col>
                <v-col cols="1" v-if="!open">
                  <editGroupType :group_type="group_type" />
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="group_type.groups">
                <div v-if="!group_type.groups.value">
                  NO GROUPS YET
                </div>
                <div v-else>
                  <v-list>
                    <v-list-item-group v-model="selectedGroup" color="primary">
                      <v-list-item
                        v-for="group in group_type.groups.value"
                        :key="group.id"
                      >
                        <!-- <v-list-item-content> -->
                        <v-list-item-title v-text="group.name">
                        </v-list-item-title>
                        <v-list-item-icon>
                          {{ recordsUnDone(group.id) }}</v-list-item-icon
                        >
                        <!-- </v-list-item-content> -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </div>
              <div v-else>
                <v-subheader> NO GROUPS YET </v-subheader>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import fire from "@/main";
import { mapGetters } from "vuex";
import newGroupType from "@/components/todo/newGroupType";
import newGroup from "@/components/todo/newGroup";
import editGroupType from "@/components/todo/modals/editGroupType";

export default {
  props: {
    group_types: null,
    lastSelectedGroupType: null,
    allRecords: null,
  },
  data: () => ({
    selectedGroupType_num: null,
    selectedGroup: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
    selectedGroupType() {
      if (
        this.selectedGroupType_num != null &&
        this.selectedGroupType_num != undefined
      ) {
        return this.group_types[this.selectedGroupType_num];
      }
      return null;
    },
  },

  async created() {
    if (this.lastSelectedGroupType) {
      this.group_types.find((r, index) => {
        if (r.id === this.lastSelectedGroupType) {
          this.selectedGroupType_num = index;
          if (r.groups) {
            if (r.groups.lastSelectedGroup && r.groups.value) {
              r.groups.value.find((g, index) => {
                if (g.id === r.groups.lastSelectedGroup) {
                  this.selectedGroup = index;
                }
              });
            }
          }
        }
      });
    }
  },
  methods: {
    groupSelected(group) {
      this.$emit("groupSelected", group);
    },
    recordsUnDone(groupID) {
      if (this.allRecords.length > 0) {
        let res = this.allRecords.filter((r) => {
          if (r.groups) {
            let f = r.groups.find((g) => g.group_id === groupID);
            if (f) {
              return r;
            }
          }
        });
        return res.filter((r) => {
          if (r.done === false) {
            return r;
          }
        }).length;
      }
      return 0;
    },
  },
  watch: {
    async selectedGroupType() {
      if (this.selectedGroupType) {
        this.$emit("groupTypeSelected", this.selectedGroupType.id);
        // console.log("selectedGroupType", this.selectedGroupType);
        if (this.selectedGroupType.groups) {
          if (
            this.selectedGroupType.groups.lastSelectedGroup &&
            this.selectedGroupType.groups.value
          ) {
            this.selectedGroupType.groups.value.find((g, index) => {
              if (g.id === this.selectedGroupType.groups.lastSelectedGroup) {
                this.selectedGroup = index;
              }
            });
          }
        }
      }
    },
    async selectedGroup(newVal) {
      this.$emit("groupSelected", this.selectedGroupType.groups.value[newVal]);
    },
  },
  components: {
    newGroupType,
    newGroup,
    editGroupType,
    // editGroup,
  },
};
</script>

<style>
</style>