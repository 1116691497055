<template>
  <div >
    <Clock />
  </div>
</template>

<script>
// @ is an alias to /src
import Clock from '@/components/clock/Clock.vue'

export default {
  name: 'ClockView',
  components: {
    Clock
  },
}
</script>
