/**
 * componentBuilder
 * 
 * @author LRon
 * @prop {Object} recivedComponent - The component to be built.
 * @data {Object} Components_type_json - The component type json.
 * @data {Object} results - The results of the component.
 * @data {Boolean} updated - Whether the component is updated or not.
 * @data {Array} allFields - The fields of the component.
 * @data {Boolean} loading - Whether the component is loading or not.
 * @components {fields} - The fields component.
 * 
 * @method updateArray - Updates the array with the given updates.
 * @method getFields - Gets the fields of the component.
 * @method updateFieldWithMyResult - Updates the field with the given result.
 * @method updateResult - Updates the result of the component.
 * @method add - Adds the component to the database.
 */
<template>
  <div>
    <div v-if="Components_type_json && !loading">
      <div v-if="Components_type_json.fields">
        <fields
          :fields="Components_type_json.fields"
          @updateResult="updateResult"
          :value="Components_type_json.value"
        />
        <div v-if="allFields.length > 0">
          <div
            v-for="oneField in allFields"
            :key="`${oneField.id + oneField.value}`"
          >
            {{ oneField.label }} - {{ oneField.value }}
          </div>
          <v-btn color="green" block @click="add">save</v-btn>
        </div>
      </div>
    </div>
    <v-divider class="my-3"></v-divider>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  name: "componentBuilder",
  props: {
    recivedComponent: null,
  },
  data: () => ({
    Components_type_json: null,
    results: {},
    updated: false,
    allFields: [],
    loading: true,
  }),
  components: {
    fields: () => import("@/components/PCB/componentsCreator/fields"),
  },
  async created() {
    let viewMessage = this;
    let itemsRef = await fire.database().ref(`PCB/components/`);
    viewMessage.Components_type_json = null;
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.Components_type_json = data;
        delete viewMessage.Components_type_json.componentFieldTypes;
        if (viewMessage.recivedComponent) {
          // viewMessage.Components_type_json = {};
          console.log(
            "this.Components_type_json",
            JSON.stringify(viewMessage.Components_type_json)
          );
          console.log(
            "this.recivedComponent.result",
            JSON.stringify(viewMessage.recivedComponent.result)
          );
          let r = await viewMessage.updateArray(
            viewMessage.Components_type_json,
            viewMessage.recivedComponent.result
          );
          viewMessage.Components_type_json = r;
          console.log(
            "viewMessage.Components_type_json",
            JSON.stringify(viewMessage.Components_type_json)
          );
        }
        this.loading = false;
      }
    });
  },
  computed: {},
  methods: {
    async updateArray(array, updates) {
      for (const [key, value] of Object.entries(array)) {
        if (typeof value === "object") {
          if (updates[key] && updates[key]["myValue"]) {
            value["myValue"] = updates[key]["myValue"];
          }
          if (updates[key]) {
            await this.updateArray(value, updates[key]);
          }
        } else if (Array.isArray(value)) {
          value.forEach(async (item, i) => {
            if (typeof item === "object") {
              await this.updateArray(item, updates[i]);
            }
          });
        }
      }
      return array;
    },
    getFields(fields) {
      let flatFields = [];
      for (let id in fields) {
        let field = fields[id];
        flatFields.push({
          label: field.label,
          value: field.value,
          id: id,
        });
        if (field.fields) {
          flatFields = flatFields.concat(this.getFields(field.fields));
        }
      }
      return flatFields;
    },
    updateFieldWithMyResult(array, fieldResult) {
      if (array.fields) {
        if (array.fields[fieldResult.id]) {
          array.fields[fieldResult.id]["myValue"] = fieldResult.value;
          return array;
        } else {
          for (const oneFieldKey in array.fields) {
            if (Object.hasOwnProperty.call(array.fields, oneFieldKey)) {
              const oneFiel = array.fields[oneFieldKey];
              if (oneFiel.type === "select") {
                if (oneFiel.options) {
                  for (const oneOptionKey in oneFiel.options) {
                    if (
                      Object.hasOwnProperty.call(oneFiel.options, oneOptionKey)
                    ) {
                      const element = oneFiel.options[oneOptionKey];
                      oneFiel.options[oneOptionKey] =
                        this.updateFieldWithMyResult(element, fieldResult);
                    }
                  }
                }
              }
            }
          }
        }
      }
      return array;
    },
    updateResult(payload) {
      this.results = payload;
      // console.log("this.results", JSON.stringify(this.results));
      this.allFields = [];
      this.allFields = this.getFields(this.results.fields);
      // console.log("this.allFields", JSON.stringify(this.allFields));
      this.updated = !this.updated;
    },
    async add() {
      let res = JSON.parse(JSON.stringify(this.Components_type_json));
      // console.log("this.allFields", this.allFields);
      // console.log("res", res);
      for (const oneFieldKey in this.allFields) {
        if (Object.hasOwnProperty.call(this.allFields, oneFieldKey)) {
          const oneField = this.allFields[oneFieldKey];
          res = this.updateFieldWithMyResult(res, oneField);
        }
      }
      // console.log("res", res);
      let d = new Date();
      if (!this.recivedComponent) {
        await fire.database().ref(`PCB/myComponents/`).push({
          timestamp: d.toString(),
          result: res,
          component: this.allFields[0].value,
        });
      } else {
        console.log("this.recivedComponent.id", this.recivedComponent.id);
        await fire
          .database()
          .ref(`PCB/myComponents/${this.recivedComponent.id}/`)
          .update({
            timestamp: d.toString(),
            result: res,
            component: this.allFields[0].value,
          });
      }
    },
  },
};
</script>
