<template>
  <!-- <div> -->
  <v-dialog
    v-if="toGroup"
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn dark text @click="create"> CREATE </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title> add group</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-9">
        <v-text-field
          @keypress.enter="create"
          label="Group Name"
          single-line
          v-model="name"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    toGroup: null,
  },
  data: () => ({
    dialog: false,
    name: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
  },
  methods: {
    async create() {
      await fire
        .database()
        .ref(
          `todo/users/${this.UID}/group_types/value/${this.toGroup.id}/groups/value`
        )
        .push({ name: this.name });
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>