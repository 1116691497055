<template>
  <div>
    <div v-if="current_working_state.value === false">
      <v-checkbox
        v-if="current_working_from_home"
        :disabled="loading_local ? true : false"
        v-model="current_working_from_home.value"
        :label="'From Home'"
      >
      </v-checkbox>
      <v-btn
        color="success"
        x-large
        block
        @click="start"
        :disabled="loading_local ? true : false"
        class=""
        >START</v-btn
      >
    </div>
    <div v-else>
      <v-btn block x-large color="primary" @click="showSettings = !showSettings"
        ><span v-if="!showSettings">Show Settings</span
        ><span v-else>Hide Settings</span></v-btn
      >
      <div v-if="showSettings" class="mx-5 my-5">
        <v-text-field
          type="number"
          label="Hour Fee:"
          v-model="per_hour_computed"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Arrival Fee:"
          v-model="arrive_price_computed"
        ></v-text-field>
        <v-text-field
          type="datetime-local"
          label="started at:"
          v-model="startTime"
        ></v-text-field>
        <v-text-field label="Description" v-model="description"> </v-text-field>
      </div>
      <div>
        <v-checkbox
          v-if="current_working_from_home"
          :disabled="loading_local ? true : false"
          v-model="current_working_from_home.value"
          :label="'From Home'"
        >
        </v-checkbox>
        <v-btn
          color="error"
          x-large
          block
          @click="stop"
          :disabled="loading_local ? true : false"
          >STOP</v-btn
        >
        <div>{{ realTime }}</div>
      </div>
    </div>
    <!-- <v-divider class="my-5"></v-divider> -->
    <!-- <oneProjectRecords
      :projectID="project.id"
    /> -->
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
// import oneProjectRecords from "@/components/records/oneProjectRecords";

export default {
  props: {
    user: null,
    project: null,
  },
  data: () => ({
    loading_local: false,
    current_time: 0,
    showSettings: false,
    per_hour: { value: 0 },
    arrive_price: { value: 0 },
    current_working_state: { value: false },
    current_working_from_home: null,
    start_timestemp: { value: "" },
    description: "",
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
    per_hour_computed: {
      get: function () {
        if (this.per_hour) {
          return this.per_hour.value;
        }
        return 0;
      },
      set: function (newVal) {
        this.per_hour.value = newVal;
      },
    },
    arrive_price_computed: {
      get: function () {
        if (this.arrive_price) {
          return this.arrive_price.value;
        }
        return 0;
      },
      set: function (newVal) {
        this.arrive_price.value = newVal;
      },
    },
    realTime: {
      get: function () {
        let date = this.current_time - new Date(this.start_timestemp.value);

        return `working timer - ${this.HMS(date)} - ${this.sum(
          this.per_hour.value,
          date
        )} (NIS)`;
      },
    },
    startTime: {
      get: function () {
        if (this.start_timestemp.value) {
          let d = new Date(this.start_timestemp.value);
          return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 16);
        } else {
          return "";
        }
      },
      set: function (newVal) {
        this.start_timestemp.value = new Date(newVal);
      },
    },
    workFromHome() {
      if (!this.current_working_from_home) {
        return null;
      }
      return this.current_working_from_home.value;
    },
  },
  created() {
    this.current_time = new Date();
    setInterval(() => {
      this.current_time = new Date();
    }, 1000);
    if (this.project) {
      this.apdateValue("per_hour");
      this.apdateValue("arrive_price");
      this.apdateValue("current_working_state");
      this.apdateValue("current_working_from_home");
      this.apdateValue("start_timestemp");
    }
  },
  methods: {
    formatTime(date) {
      date = new Date(date);
      return date;
    },
    HMS(time) {
      time = Math.floor(time / 1000);
      let res = "";
      let h = Math.floor(time / 60 / 60);
      time = time - h * 60 * 60;
      if (h < 10) {
        res += `0${h}`;
      } else {
        res += h;
      }
      res += ":";

      let m = Math.floor(time / 60);
      time = time - m * 60;
      if (m < 10) {
        res += `0${m}`;
      } else {
        res += m;
      }
      res += ":";

      let s = time;
      if (s < 10) {
        res += `0${s}`;
      } else {
        res += s;
      }

      return res;
    },
    sum(ph, time) {
      time = Math.floor(time / 1000);
      let s = ph * (time / 60 / 60);
      return Math.round(s * 100) / 100;
    },
    apdateValue(varName) {
      if (this.project[varName]) {
        let res = null;
        for (const key in this.project[varName]) {
          if (Object.hasOwnProperty.call(this.project[varName], key)) {
            const element = this.project[varName][key];
            if (element.uid === this.UID) {
              element.id = key;
              res = element;
            }
          }
        }
        if (res) {
          this.$data[varName] = res;
        }
      }
    },
    async start() {
      this.loading_local = true;
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/current_working_state/${this.current_working_state.id}`
        )
        .update({ value: true });
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/start_timestemp/${this.start_timestemp.id}`
        )
        .update({ value: Date() });
      this.$emit("update");
    },
    async stop() {
      this.loading_local = true;
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/current_working_state/${this.current_working_state.id}`
        )
        .update({ value: false });
      let stop_time = new Date();
      let d = new Date(this.start_timestemp.value);
      let msg = {
        uid: this.UID,
        start_timestemp: d.toString(),
        stop_timestemp: stop_time.toString(),
        current_working_from_home: this.current_working_from_home.value,
        arrive_price: parseInt(this.arrive_price.value) || 0,
        per_hour: parseInt(this.per_hour.value),
        description: this.description,
      };
      await fire
        .database()
        .ref(`projects/${this.project.id}/working_hours/`)
        .push(msg);
      this.$emit("update");
    },
  },
  watch: {
    async per_hour_computed(newValue, oldValue) {
      if (oldValue != "") {
        console.log(this.per_hour.value);
        this.loading_local = true;
        await fire
          .database()
          .ref(`projects/${this.project.id}/per_hour/${this.per_hour.id}`)
          .update({ value: this.per_hour.value });
        this.loading_local = false;
      }
    },
    async arrive_price_computed(newValue, oldValue) {
      if (oldValue != "") {
        console.log(this.arrive_price.value);
        this.loading_local = true;
        await fire
          .database()
          .ref(
            `projects/${this.project.id}/arrive_price/${this.arrive_price.id}`
          )
          .update({ value: this.arrive_price.value });
        this.loading_local = false;
      }
    },
    async startTime(newValue, oldValue) {
      if (oldValue != "") {
        let d = new Date(this.start_timestemp.value);
        console.log("d", d);
        this.loading_local = true;
        await fire
          .database()
          .ref(
            `projects/${this.project.id}/start_timestemp/${this.start_timestemp.id}`
          )
          .update({ value: d.toString() });
        this.loading_local = false;
      }
    },
    async workFromHome(newValue, oldValue) {
      if (oldValue != null) {
        console.log("workFromHome", this.workFromHome);
        this.loading_local = true;
        await fire
          .database()
          .ref(
            `projects/${this.project.id}/current_working_from_home/${this.current_working_from_home.id}`
          )
          .update({ value: this.workFromHome });
        this.loading_local = false;
      }
    },
  },
  components: {
    // oneProjectRecords,
  },
};
</script>

<style>
</style>