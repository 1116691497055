<template>
  <v-expansion-panels :accordion="true" >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ header }} ({{ components.length }})</v-expansion-panel-header
      >
      <v-expansion-panel-content
        v-for="component in components"
        :key="component.id"
      >
      <v-card flat class="ml-3">
        {{ component.name }}
      </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    header: {
      default: "header",
    },
    components: {},
  },
};
</script>

<style>
</style>