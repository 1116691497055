<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      v-if="user"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="primary" v-bind="attrs" v-on="on">
          {{ user.name }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark text @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn dark icon @click="remove">
            <v-icon color="error">mdi-delete-forever</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> {{ user.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            class="my-0 py-0"
            type="number"
            label="Hour Payment"
            single-line
            v-model="per_hour.value"
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            type="number"
            label="Arrival Fee"
            single-line
            v-model="arrive_price.value"
          ></v-text-field>
          <v-checkbox
            class="my-0 py-0"
            v-model="current_working_from_home.value"
            :label="'from home'"
          >
          </v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            v-model="current_working_state.value"
            :label="`working now`"
          >
          </v-checkbox>
          <!-- <v-text-field
            class="my-0 py-0"
            type="datetime-local"
            label="Start Timestamp"
            single-line
            clearable
            v-model="start_timestemp.value"
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            label="Last Selected Project"
            single-line
            clearable
            v-model="user.last_selected_project"
          ></v-text-field> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

// import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

// import newUserToProject from "@/components/modals/newUserToProject";

export default {
  props: {
    user: null,
    project: null,
  },
  data: () => ({
    dialog: false,
    per_hour: { value: 0 },
    arrive_price: { value: 0 },
    current_working_state: { value: false },
    current_working_from_home: { value: false },
    start_timestemp: { value: "" },
    // last_selected_project: { value: "" },
  }),
  components: {
    // newUserToProject,
  },
  created() {
    // console.log("user",this.user);
    if (this.project) {
      this.apdateValue("per_hour");
      this.apdateValue("arrive_price");
      this.apdateValue("current_working_state");
      this.apdateValue("current_working_from_home");
      this.apdateValue("start_timestemp");
      // this.apdateValue("last_selected_project");
    }
  },
  computed: {},
  methods: {
    apdateValue(varName) {
      if (this.project[varName]) {
        let res = null;
        for (const key in this.project[varName]) {
          if (Object.hasOwnProperty.call(this.project[varName], key)) {
            const element = this.project[varName][key];
            if (element.uid === this.user.uid) {
              element.id = key;
              res = element;
            }
          }
        }
        if (res) {
          this.$data[varName] = res;
        }
      }
    },
    async save() {
      // await fire
      //   .database()
      //   .ref(`projects/${this.project.id}/`)
      //   .update({ name: this.project.name });
      this.dialog = false;
    },
    async remove() {
      // console.log("per_hour", this.per_hour);
      // console.log("arrive_price", this.arrive_price);

      // console.log("current_working_state", this.current_working_state);
      // console.log("current_working_from_home", this.current_working_from_home);
      // console.log("start_timestemp", this.start_timestemp);
      // console.log("last_selected_project", this.last_selected_project);
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/arrive_price/${this.arrive_price.id}/`
        )
        .set(null);
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/current_working_state/${this.current_working_state.id}/`
        )
        .set(null);

      await fire
        .database()
        .ref(
          `projects/${this.project.id}/current_working_from_home/${this.current_working_from_home.id}/`
        )
        .set(null);
      await fire
        .database()
        .ref(
          `projects/${this.project.id}/start_timestemp/${this.start_timestemp.id}/`
        )
        .set(null);
      // await fire
      //   .database()
      //   .ref(
      //     `projects/${this.project.id}/last_selected_project/${this.last_selected_project.id}/`
      //   )
      //   .set(null);
      await fire
        .database()
        .ref(`projects/${this.project.id}/per_hour/${this.per_hour.id}/`)
        .set(null);
      this.dialog = false;
      // this.$emit("remove");
    },
  },
  // mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>