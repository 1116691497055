<template>
  <v-card v-if="option" class="ma-3">
    <v-card-title class="py-0">
      <newOption class="mr-3" v-if="option" :relativePath="myPath" :option="option" />
      {{ option.value }}
      <v-spacer></v-spacer>
      
      <newField :relativePath="myPath" />
    </v-card-title>
    <v-card-text v-if="fields">
      <div v-for="field in fields" :key="field.id">
        <oneField :relativePath="myPath" :fieldID="field.id" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import fire from "@/main";
import newField from "@/components/PCB/componentSketches/newField";
import oneField from "@/components/PCB/componentSketches/oneField";
import newOption from "@/components/PCB/componentSketches/newOption";

export default {
  name: "oneOption",
  props: {
    oprionID: null,
    relativePath: {
      default: "",
    },
  },
  data: () => ({
    myPath: "",
    option: null,
  }),
  computed: {
    fields() {
      if (this.option) {
        if (this.option.fields) {
          return Object.keys(this.option.fields).map((key) => ({
            ...this.option.fields[key],
            id: key,
          }));
        }
      }
      return null;
    },
  },
  async created() {
    this.myPath = `${this.relativePath}/options/${this.oprionID}`;
    let viewMessage = this;
    let itemsRef = await fire.database().ref(`${this.myPath}`);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.option = data;
      }
      // console.log("viewMessage.component", viewMessage.component);
    });
  },
  components: {
    newField,
    oneField,
    newOption
  },
};
</script>

<style>
</style>