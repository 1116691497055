<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">mdi-pencil </v-icon>
      </template>
      <v-card :loading="loading_tmp" v-if="record">
        <v-toolbar dark color="primary">
          <v-btn v-if="!loading_tmp" dark text @click="save"> SAVE </v-btn>
          <v-btn v-if="!loading_tmp" dark text @click="del"> DELETE </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> EDIT RECORD</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-checkbox v-model="service" :label="'Servise'"></v-checkbox>
          <v-text-field
            v-if="service"
            type="number"
            label="Service Fee:"
            v-model="service_fee"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="number"
            label="Hour Fee:"
            v-model="per_hour"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="number"
            label="Arrive Fee:"
            v-model="arrive_price"
          ></v-text-field>
          <v-checkbox
            v-if="!service"
            v-model="current_working_from_home"
            :label="'From Home'"
          >
          </v-checkbox>
          <v-text-field
            type="datetime-local"
            label="started at:"
            v-model="start_timestemp_computed"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="datetime-local"
            label="stoped at:"
            v-model="stop_timestemp_computed"
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="description"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    record: null,
    projectID: null,
  },
  data: () => ({
    loading_tmp: false,
    dialog: false,
    current_working_from_home: false,
    start_timestemp: new Date(),
    stop_timestemp: new Date(),
    per_hour: null,
    arrive_price: null,
    description: "",
    service: false,
    service_fee: 0,
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
    start_timestemp_computed: {
      get: function () {
        let d = new Date(this.start_timestemp);
        return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
      },
      set: function (newVal) {
        this.start_timestemp = new Date(newVal);
      },
    },
    stop_timestemp_computed: {
      get: function () {
        let d = new Date(this.stop_timestemp);
        let res = null;
        try {
          res = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 16);
        } catch (error) {
          console.log(error);
        }
        return res;
      },
      set: function (newVal) {
        this.stop_timestemp = new Date(newVal);
      },
    },
  },
  created() {
    this.per_hour = this.record.per_hour;
    this.arrive_price = this.record.arrive_price;
    this.current_working_from_home = this.record.current_working_from_home;
    this.start_timestemp = this.record.start_timestemp;
    this.stop_timestemp = this.record.stop_timestemp;
    this.description = this.record.description || "";
    this.service = this.record.service || false;
    this.service_fee = this.record.service_fee || 0;
  },
  methods: {
    async save() {
      let msg = {};
      if (!this.service) {
        msg = {
          uid: this.UID,
          start_timestemp: new Date(this.start_timestemp).toString(),
          stop_timestemp: new Date(this.stop_timestemp).toString(),
          current_working_from_home: this.current_working_from_home,
          arrive_price: parseInt(this.arrive_price),
          per_hour: parseInt(this.per_hour),
          description: this.description,
          service: this.service,
        };
      } else {
        msg = {
          uid: this.UID,
          start_timestemp: new Date(this.start_timestemp).toString(),
          // stop_timestemp: new Date(this.stop_timestemp).toString(),
          // current_working_from_home: this.current_working_from_home,
          // arrive_price: parseInt(this.arrive_price),
          // per_hour: parseInt(this.per_hour),
          description: this.description,
          service: this.service,
          service_fee: parseInt(this.service_fee),
        };
      }
      console.log("msg id-" + this.record.id, msg);
      await fire
        .database()
        .ref(`projects/${this.projectID}/working_hours/${this.record.id}`)
        .update(msg);
      this.dialog = false;
    },
    async del() {
      await fire
        .database()
        .ref(`projects/${this.projectID}/working_hours/${this.record.id}/`)
        .set(null);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>