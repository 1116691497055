<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> add project </v-btn>
      </template>
      <v-card :loading="loading_tmp">
        <v-toolbar dark color="primary">
          <v-btn v-if="!loading_tmp" dark text @click="add"> ADD </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> Add Project</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-select
            v-model="project"
            :hint="'select project'"
            :items="projectsToShow"
            item-text="name"
            item-value="id"
            :label="'select project'"
            persistent-hint
            return-object
            single-line
          ></v-select>
          <!-- <v-text-field
            type="number"
            label="Hour Payment"
            single-line
            v-model="per_hour"
          ></v-text-field>
          <v-text-field
            type="number"
            label="Arrival Fee"
            single-line
            v-model="arrive_price"
          ></v-text-field> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

export default {
  props: {
    group: null,
    projectsInGropu: null,
  },
  data: () => ({
    loading_tmp: false,
    dialog: false,
    project: null,
    // per_hour: null,
    // arrive_price: null,
  }),
  computed: {
    ...mapGetters(["loading", "userInfo"]),
    projectsToShow() {
      let arr = [];
      arr = [...arr, ...this.all_my_projects];

      if (this.projectsInGropu) {
        if (this.projectsInGropu.length > 0) {
          let arr_tmp = [];
          for (const key in arr) {
            if (Object.hasOwnProperty.call(arr, key)) {
              const oneProject = arr[key];
              let sameProjects = this.projectsInGropu.filter((sameProject) => {
                if (sameProject.value === oneProject.id) {
                  return sameProject;
                }
              });
              let isNewProject = true;
              if (sameProjects && sameProjects.length > 0) {
                isNewProject = false;
              }
              if (isNewProject) {
                arr_tmp.push(oneProject);
              }
            }
          }
          arr = arr_tmp;
        }
      }
      return arr;
    },
  },
  async created() {
    await this.loadUsers();
    await this.loadProjects();
  },
  methods: {
    async add() {
      this.loading_tmp = true;
      await fire
        .database()
        .ref(`groups/${this.group.id}/projects`)
        .push({ uid: this.UID, value: this.project.id });
      this.loading_tmp = false;
      this.dialog = false;
      // this.$emit("loading")
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>

<style>
</style>