<template>
  <v-dialog persistent v-model="showDialog" max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mt-2 white--text"
        color="deep-purple darken-2"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ buttonName }}
        <v-icon v-if="!disabled" right>mdi-currency-ils</v-icon>
      </v-btn>
    </template>
    <div>
      <v-card flat>
        <v-card-title class="text-h5 deep-purple darken-2 white--text mb-5">
          {{ buttonName }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="showDialog = false"> mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="d-flex flex-wrap" flat tile>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :type="'number'"
                  outlined
                  label="סכום שהועבר"
                  append-icon="mdi-currency-ils"
                  v-model="price"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :type="'number'"
                  outlined
                  label="חודש"
                  v-model="month"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :type="'number'"
                  outlined
                  label="שנה"
                  v-model="year"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="הערות"
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" class="white--text" color="deep-purple darken-2">
            {{ buttonName }}
            <v-icon v-if="!disabled" right>mdi-currency-ils</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    buttonName: {
      default: "Add Payment",
    },
    disabled: {
      default: false,
    },
    room: {},
    // startPrice: {
    //   default: 0,
    // },
  },
  data() {
    return {
      showDialog: false,
      price: 0,
      year: 0,
      month: 0,
      description: "",
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  created() {
    // console.log(this.room);
    let d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.price = this.room.shekels;
    this.description = this.room.name;
  },
  methods: {
    async save() {
      // this.$emit("makeProposal", {
      //   comments: this.comments,
      //   price: this.price,
      // });
      await this.$store.dispatch("loading", true);
      let msg = `room_payment (id, timestamp, roomID, sum, year, month, description) VALUES (NULL, current_timestamp(), ${this.room.id}, ${this.price}, ${this.year}, ${this.month}, "${this.description}")`;
      // console.log(msg);
      await this.$store.dispatch('ADD_PAYMENT',{msg:msg})
      this.showDialog = false;
      await this.$store.dispatch("loading", false);
    },
  },
};
</script>

<style>
</style>