<template>
  <div>
    <h1>Component Sketches</h1>
    <selectComponent @selectedComponentFunc="selectedComponentFunc" />
    <v-divider></v-divider>
    <div v-if="selectedComponent">
      <oneOption
        :key="selectedComponent.id"
        :oprionID="selectedComponent.id"
        :relativePath="`PCB/components/fields/ID1`"
      />
    </div>
  </div>
</template>

<script>
import oneOption from "@/components/PCB/componentSketches/oneOption";
import selectComponent from "@/components/PCB/componentSketches/selectComponent";

export default {
  data: () => ({
    selectedComponent: null,
  }),
  created() {},
  methods: {
    selectedComponentFunc(payload) {
      if (payload) {
        this.selectedComponent = payload;
      }
    },
  },
  components: {
    oneOption,
    selectComponent,
  },
};
</script>

<style>
</style>