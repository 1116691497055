<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block x-large color="primary" v-bind="attrs" v-on="on">
          NEW RECORD
        </v-btn>
      </template>
      <v-card :loading="loading_tmp">
        <v-toolbar dark color="primary">
          <v-btn v-if="!loading_tmp" dark text @click="add"> ADD </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> NEW RECORD</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-checkbox v-model="service" :label="'Servise'"></v-checkbox>
          <v-text-field
            v-if="service"
            type="number"
            label="Service Fee:"
            v-model="service_fee"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="number"
            label="Hour Fee:"
            v-model="per_hour"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="number"
            label="Arrive Fee:"
            v-model="arrive_price"
          ></v-text-field>
          <v-checkbox
            v-if="!service"
            v-model="current_working_from_home"
            :label="'From Home'"
          ></v-checkbox>
          <v-text-field
            type="datetime-local"
            label="started at:"
            v-model="start_timestemp_computed"
          ></v-text-field>
          <v-text-field
            v-if="!service"
            type="datetime-local"
            label="stoped at:"
            v-model="stop_timestemp_computed"
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="description"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    projectID: null,
    uid: null,
  },
  data: () => ({
    loading_tmp: false,
    dialog: false,
    current_working_from_home: false,
    start_timestemp: new Date(),
    stop_timestemp: new Date(),
    per_hour: null,
    arrive_price: null,
    description: "",
    service_fee: 0,
    service: false,
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
    uid_computed() {
      if (this.uid) {
        return this.uid;
      }
      if (this.UID) {
        return this.UID;
      }
      return null;
    },
    start_timestemp_computed: {
      get: function () {
        let d = new Date(this.start_timestemp);
        return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
      },
      set: function (newVal) {
        this.start_timestemp = new Date(newVal);
      },
    },
    stop_timestemp_computed: {
      get: function () {
        let d = new Date(this.stop_timestemp);
        return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
      },
      set: function (newVal) {
        this.stop_timestemp = new Date(newVal);
      },
    },
  },
  async created() {
    let viewMessage = this;
    let itemsRef2 = await fire
      .database()
      .ref(`projects/${viewMessage.projectID}/per_hour/`)
      .orderByChild("uid")
      .equalTo(this.uid_computed);
    await itemsRef2.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        let res = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        }));
        if (res.length > 0) {
          viewMessage.per_hour = res[0].value;
        }
      }
    });
    let itemsRef = await fire
      .database()
      .ref(`projects/${viewMessage.projectID}/arrive_price/`)
      .orderByChild("uid")
      .equalTo(this.uid_computed);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        let res = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        }));
        if (res.length > 0) {
          viewMessage.arrive_price = res[0].value;
        }
      }
    });
  },
  methods: {
    async add() {
      let msg = {};
      if (!this.service) {
        msg = {
          uid: this.uid_computed,
          start_timestemp: new Date(this.start_timestemp).toString(),
          stop_timestemp: new Date(this.stop_timestemp).toString(),
          current_working_from_home: this.current_working_from_home,
          arrive_price: parseInt(this.arrive_price),
          per_hour: parseInt(this.per_hour),
          description: this.description,
          service: this.service,
        };
      } else {
        msg = {
          uid: this.uid_computed,
          start_timestemp: new Date(this.start_timestemp).toString(),
          // stop_timestemp: new Date(this.stop_timestemp).toString(),
          // current_working_from_home: this.current_working_from_home,
          // arrive_price: parseInt(this.arrive_price),
          // per_hour: parseInt(this.per_hour),
          description: this.description,
          service: this.service,
          service_fee: parseInt(this.service_fee),
        };
      }
      await fire
        .database()
        .ref(`projects/${this.projectID}/working_hours/`)
        .push(msg);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>