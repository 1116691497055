<template>
  <div>
    <v-select
      class="ma-3 pa-3"
      :loading="loading"
      :disabled="loading ? true : false"
      v-if="user && all_my_projects.length > 0"
      v-model="selectedProject"
      :hint="'select project'"
      :items="all_my_projects"
      item-text="name"
      item-value="id"
      :label="'select project'"
      persistent-hint
      return-object
      single-line
    ></v-select>
    <v-divider></v-divider>
    <ClockForProject
      class="ma-3 pa-3"
      v-if="selectedProject && !loading && user"
      :user="user"
      :project="selectedProject"
      @update="update"
      :key="ClockForProject_key"
    />
    <v-card class="mx-3 mb-3" elevation="5">
      <v-card-title>
        <v-tabs color="primary" v-model="tab" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <oneProjectRecords
              v-if="selectedProject && !loading && user"
              :projectID="selectedProject.id"
            />
          </v-tab-item>
          <v-tab-item>
            <allProjectRecords :allProjects="all_my_projects" />
          </v-tab-item>
          <v-tab-item>
            <groupProjectsRecords :allProjects="all_my_projects" :allGroups="all_my_groups"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

import ClockForProject from "@/components/clock/ClockForProject";
import oneProjectRecords from "@/components/records/oneProjectRecords";
import allProjectRecords from "@/components/records/allProjectRecords";
import groupProjectsRecords from "@/components/records/groupProjectsRecords";

export default {
  name: "Home",
  components: {
    ClockForProject,
    oneProjectRecords,
    allProjectRecords,
    groupProjectsRecords,
  },
  data: () => ({
    selectedProject: null,
    user: null,
    ClockForProject_key: false,
    //TABS
    tab: null,
    items: ["CURRENT PROJECT", "ALL PROJECTS", "GROUPS"],
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
  },
  async created() {
    await this.loadProjects();
    await this.getUser();
    await this.loadGroups();
  },
  watch: {
    async UID() {
      this.user = null;
      await this.getUser();
    },
    async selectedProject() {
      if (this.selectedProject) {
        if (this.user.last_selected_project !== this.selectedProject.id) {
          await this.$store.dispatch("loading", true);
          await fire
            .database()
            .ref(`users/${this.UID}/`)
            .update({ last_selected_project: this.selectedProject.id });
          await this.$store.dispatch("loading", false);
        }
      }
    },
  },
  methods: {
    async getUser() {
      if (this.UID) {
        let viewMessage = this;
        let itemsRef = await fire.database().ref(`users/${this.UID}/`);
        await itemsRef.on("value", async (snapshot) => {
          let data = snapshot.val();
          if (data) {
            viewMessage.user = data;
            this.selectedProject = null;
            if (this.all_projects.length > 0) {
              this.all_projects.forEach((project) => {
                if (this.user.last_selected_project === project.id) {
                  this.selectedProject = project;
                }
              });
            }
          }
        });
      }
    },
    async update() {
      await this.$store.dispatch("loading", true);
      await this.loadProjects();
      await this.getUser();
      await this.$store.dispatch("loading", false);
    },
  },
  mixins: [loadGlobalsMixin],
};
</script>
