<template>
  <div>
    <v-card>
      <v-card-title class="primary white--text">
        {{ selectedGroup.name || "All Records" }}
        <v-spacer></v-spacer>
        <editGroup
          v-if="selectedGroup"
          :group_type="lastSelectedGroupType"
          :group="selectedGroup"
        />
      </v-card-title>
      <v-card-title>
        <v-text-field
          label="enter new record"
          v-model="newRectord"
          :append-outer-icon="newRectord ? 'mdi-send' : ''"
          @click:append-outer="sendMessage"
          @keypress.enter="sendMessage"
          clearable
          rounded
          filled
        ></v-text-field>
      </v-card-title>
      <v-card-text v-if="records.length > 0">
        <v-subheader>Not Done</v-subheader>
        <oneRecord
          @groupTypeSelected="groupTypeSelected"
          link
          v-for="record in recordsUnDone"
          :key="record.id"
          :record="record"
          :done="false"
          :group_types="group_types"
        />
        <v-divider></v-divider>
        <v-subheader>Done</v-subheader>
        <oneRecord
          @groupTypeSelected="groupTypeSelected"
          link
          v-for="record in recordDone"
          :key="record.id"
          :record="record"
          :done="true"
          :group_types="group_types"
        />
      </v-card-text>
      <v-card-text v-else> NO RECORDS YET </v-card-text>
    </v-card>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import oneRecord from "@/components/todo/oneRecord";
import editGroup from "@/components/todo/modals/editGroup";

export default {
  props: {
    selectedGroup: null,
    group_types: null,
    group_type: null,
    allRecords: null,
    showAllRecords: {
      default: false,
    },
  },
  data: () => ({
    newRectord: "",
    selectedRecord: null,
    lastSelectedGroupType: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
    records() {
      if (this.allRecords) {
        if (!this.showAllRecords) {
          let res = this.allRecords.filter((r) => {
            if (r.groups) {
              let f = r.groups.find(
                (g) => g.group_id === this.selectedGroup.id
              );
              if (f) {
                return r;
              }
            }
          });
          return res;
        } else {
          return this.allRecords;
        }
      }
      return [];
    },
    recordsUnDone() {
      if (this.records.length > 0) {
        return this.records.filter((r) => {
          if (r.done === false) {
            return r;
          }
        });
      }
      return [];
    },
    recordDone() {
      if (this.records.length > 0) {
        return this.records.filter((r) => {
          if (r.done === true) {
            return r;
          }
        });
      }
      return [];
    },
  },
  async created() {
    if (!this.showAllRecords) {
      if (this.group_type) {
        this.group_types.find((r) => {
          if (r.id === this.group_type) {
            this.lastSelectedGroupType = r;
          }
        });
      }
    }
  },
  methods: {
    groupTypeSelected(id) {
      this.$emit("groupTypeSelected", id);
    },
    async sendMessage() {
      if (this.newRectord && this.newRectord != "") {
        console.log("this.newRectord", this.newRectord);
        let pushedPostRef = await fire
          .database()
          .ref(`todo/users/${this.UID}/records/`)
          .push({
            text: this.newRectord,
            done: false,
            created: Date(),
            last_modified: Date(),
          });
        let postId = pushedPostRef.getKey();
        if (!this.showAllRecords) {
          await fire
            .database()
            .ref(`todo/users/${this.UID}/records/${postId}/groups`)
            .push({
              group_id: this.selectedGroup.id,
            });
        }
        this.newRectord = "";
      }
    },
  },
  components: {
    oneRecord,
    editGroup,
  },
};
</script>

<style>
</style>