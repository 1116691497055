<template>
  <div v-if="allProjects" class="ma-3">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="3">
          <v-select
            v-model="selectedYear"
            :hint="'select year'"
            :items="allYears"
            :label="'select year'"
            persistent-hint
            single-line
            class="ma-5"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            v-model="selectedMonth"
            :hint="'select month'"
            :items="allMonths"
            :item-text="'name'"
            :item-value="'index'"
            :label="'select month'"
            persistent-hint
            single-line
            class="ma-5"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card-title>
            <v-spacer />
            <h2>
              TOTAL:
              <span class="red--text">
                {{ Math.round(TOTAL * 100) / 100 }}
                <v-icon color="red">mdi-currency-ils</v-icon>
              </span>
            </h2>
            <v-spacer />
          </v-card-title>
        </v-col>
      </v-row>
    </v-container>

    <v-card v-for="project in allProjects" :key="project.id" flat>
      <v-card-title>
        <!-- <v-spacer /> -->
        <v-btn x-large block @click="toggleProject(project.id)">
          <h3>
            {{ project.name }} -
            {{ Math.round(allProjectsSum[project.id].sum * 100) / 100 }}
          </h3>
          <v-icon small>mdi-currency-ils</v-icon>
        </v-btn>
        <!-- <v-spacer /> -->
      </v-card-title>
      <v-card-text>
        <oneProjectRecords
          v-show="
            allProjectsSum[project.id].open === true && (update || !update)
          "
          :projectID="project.id"
          :selectedMonth="selectedMonth"
          :selectedYear="selectedYear"
          :inGroup="true"
          @sum="sum"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import oneProjectRecords from "@/components/records/oneProjectRecords";
export default {
  props: {
    allProjects: null,
  },
  data: () => ({
    allProjectsSum: null,
    selectedMonth: new Date().getMonth() + 1,
    allMonths: [
      { index: 1, name: "January" },
      { index: 2, name: "February" },
      { index: 3, name: "March" },
      { index: 4, name: "April" },
      { index: 5, name: "May" },
      { index: 6, name: "June" },
      { index: 7, name: "July" },
      { index: 8, name: "August" },
      { index: 9, name: "September" },
      { index: 10, name: "October" },
      { index: 11, name: "November" },
      { index: 12, name: "December" },
    ],
    allYears: [2022, 2023],
    selectedYear: new Date().getFullYear(),
    update: false,
  }),
  computed: {
    TOTAL() {
      this.update;
      let s = 0;
      if (this.allProjectsSum) {
        for (const key in this.allProjectsSum) {
          if (Object.hasOwnProperty.call(this.allProjectsSum, key)) {
            const element = this.allProjectsSum[key];
            s += element.sum;
          }
        }
      }
      return s;
    },
  },
  created() {
    if (this.allProjects) {
      this.allProjectsSum = {};
      this.allProjects.forEach((proj) => {
        this.allProjectsSum[proj.id] = {};
        this.allProjectsSum[proj.id].sum = 0;
        this.allProjectsSum[proj.id].open = false;
      });
    }
  },
  methods: {
    sum(payload) {
      this.update = !this.update;
      this.allProjectsSum[payload.id].sum = payload.sum;
    },
    toggleProject(id) {
      this.allProjectsSum[id].open = !this.allProjectsSum[id].open;
      this.update = !this.update;
    },
  },
  components: {
    oneProjectRecords,
  },
};
</script>

<style>
</style>