<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="red lighten-2" dark v-bind="attrs" v-on="on"
        ><v-icon> mdi-information-variant</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ component.pin }} | {{ component.name }}
      </v-card-title>

      <v-card-text>
        <div v-if="component.name">
          name : {{ component.name }}
          <v-divider></v-divider>
        </div>
        <div v-if="component.pin">
          start pin : {{ component.pin }}
          <v-divider></v-divider>
        </div>
        <div v-if="component.pins_number">
          <span>pins : {{ component.pins_number }}</span>
          <v-divider></v-divider>
        </div>
        <div v-if="component.cableNumber">
          <span>cable : {{ component.cableNumber }}</span>
          <v-divider></v-divider>
        </div>
        <div v-if="component.cableColor">
          <span>colors : {{ component.cableColor }}</span>
          <v-divider></v-divider>
        </div>
        <div v-if="component.commonColor">
          <span>common : {{ component.commonColor }}</span>
          <v-divider></v-divider>
        </div>
        <div v-if="component.description != ''">
          <span>description : {{ component.description }}</span>
          <v-divider></v-divider>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> CLOSE </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: {
    component: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>