<template>
  <v-card v-if="field" class="ma-3">
    <v-card-title class="py-0">
      <newField class="mr-3" v-if="field != null" :relativePath="myPath" :field="field" />
      <span @click="fold = !fold">{{ field.label }} - {{ field.type }}</span>
      <v-spacer></v-spacer>
      <v-icon @click="fold = !fold" v-if="options && field.type === 'select'"
        >mdi-unfold-more-horizontal</v-icon
      >
      
      <newOption v-if="field.type === 'select'" :relativePath="myPath" />
    </v-card-title>
    <v-card-text v-if="options && field.type === 'select' && fold">
      <div v-for="option in options" :key="option.id">
        <oneOption :oprionID="option.id" :relativePath="myPath" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import fire from "@/main";
import newOption from "@/components/PCB/componentSketches/newOption";
import newField from "@/components/PCB/componentSketches/newField";

export default {
  props: {
    fieldID: null,
    relativePath: {
      default: "",
    },
  },
  data: () => ({
    myPath: "",
    field: null,
    fold: false,
  }),
  computed: {
    options() {
      if (this.field) {
        if (this.field.options) {
          return Object.keys(this.field.options).map((key) => ({
            ...this.field.options[key],
            id: key,
          }));
        }
      }
      return null;
    },
  },
  async created() {
    this.myPath = `${this.relativePath}/fields/${this.fieldID}`;
    // console.log("oneField myPath", this.myPath);
    let viewMessage = this;
    let itemsRef = await fire.database().ref(`${this.myPath}`);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.field = data;
      }
      // console.log("viewMessage.field", viewMessage.field);
    });
  },
  components: {
    oneOption: () => import("@/components/PCB/componentSketches/oneOption"),
    newOption,
    newField,
    // oneOption,
  },
};
</script>

<style>
</style>