<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="white">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark text @click="save"> save </v-btn>
          <v-btn dark text @click="remove"> delete </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> EDIT</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            @keypress.enter="save"
            label="Group Type Name"
            single-line
            v-model="group.name"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    group_type: null,
    group: null,
  },
  data: () => ({
    dialog: false,
    // name: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
  },
  methods: {
    async save() {
      console.log();
      await fire
        .database()
        .ref(
          `todo/users/${this.UID}/group_types/value/${this.group_type.id}/groups/value/${this.group.id}`
        )
        .update({ name: this.group.name });
      this.dialog = false;
    },
    async remove() {
      console.log(
        "removing ",
        `todo/users/${this.UID}/group_types/value/${this.group_type.id}/groups/value/${this.group.id}`
      );
      await fire
        .database()
        .ref(
          `todo/users/${this.UID}/group_types/value/${this.group_type.id}/groups/value/${this.group.id}`
        )
        .set(null);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>