<template>
  <v-expansion-panel>
    <v-expansion-panel-header v-if="!loading" :color="ifPayed() ? '' : 'red'">
      PAYMENTS ({{ shekels }} shekels/month )
    </v-expansion-panel-header>
    <v-expansion-panel-header v-if="loading">
      <v-progress-linear
        color="indigo"
        height="10"
        indeterminate
        v-if="loading"
      ></v-progress-linear>
    </v-expansion-panel-header>
    <div v-if="!loading">
      <v-expansion-panel-content>
        <PAY_DIALOG :room="room" />
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <div v-if="languagesPayed">multi-language payed</div>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-for="payment in payments" :key="payment.id">
        {{ payment.timestamp | date("date") }} - ({{ payment.month }}.{{
          payment.year
        }}) - {{ payment.sum }}
        <v-icon x-small left>mdi-currency-ils</v-icon>
      </v-expansion-panel-content>
    </div>
  </v-expansion-panel>
</template>

<script>
import PAY_DIALOG from "@/components/ROOMS/ModalWindow/PAY_DIALOG";

import { mapGetters } from "vuex";
export default {
  props: {
    roomIndex: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  components: {
    PAY_DIALOG,
  },
  computed: {
    ...mapGetters(["ROOMS"]),
    shekels() {
      return (
        this.ROOMS.find((r) => {
          if (r.id === this.roomIndex) return r;
        }).shekels || ""
      );
    },
    languagesPayed() {
      return (
        this.ROOMS.find((r) => {
          if (r.id === this.roomIndex) return r;
        }).languagesPayed || false
      );
    },
    payments() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      }).payments;
    },
    room() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      });
    },
  },
  methods: {
    async putPaymentsInRooms() {
      let payments = await this.$store.dispatch("GET_AllTableElements", {
        msg: "room_payment WHERE roomID=" + this.room.id,
        var: "PAYMENTS",
      });
      this.room.payments = payments;
    },
    ifPayed() {
      let d = new Date();
      let currentMonth = d.getMonth() + 1;
      let currentYear = d.getFullYear();
      let currentPay = this.payments.find((y) => {
        if (
          parseInt(y.month) === parseInt(currentMonth) &&
          parseInt(y.year) === parseInt(currentYear)
        ) {
          return y;
        }
      });
      if (currentPay) {
        this.$store.dispatch("PAYED", {
          roomIndex: this.roomIndex,
          payed: true,
        });
        return true;
      }
      this.$store.dispatch("PAYED", {
        roomIndex: this.roomIndex,
        payed: false,
      });
      return false;
    },
  },
  async created() {
    await this.putPaymentsInRooms();
    this.loading = false;
  },
};
</script>

<style>
</style>