<template>
  <div>
    <PCB />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PCB from "@/components/PCB/PCB";
export default {
  name: "pcb",
  computed: {
    ...mapGetters(["UID", "userInfo"]),
  },
  components: {
    PCB,
  },
};
</script>
