<template>
  <div>
    <v-progress-linear
      color="indigo"
      height="10"
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <v-text-field
      v-model="VroomSearch"
      label="SEARCH ROOM"
      placeholder="serch room by name"
      append-icon="mdi-magnify"
      filled
      rounded
      dense
      single-line
      clearable
      class="ma-3 pa-1"
    ></v-text-field>
    <div v-if="userInfo && !loading">
      <v-checkbox dense v-model="showOnlyNotPayed" label="show who didn't pay">
      </v-checkbox>
      <span v-if="monthSum != 0">
        {{ monthSum }}<v-icon x-small left>mdi-currency-ils</v-icon> ({{
          Math.round(monthSum * 1.17 * 100) / 100
        }}
        w/maam)
      </span>
    </div>
    <div v-if="!loading">
      <div class="ma-1" v-for="(room, index) in filteredRooms" :key="room.id">
        <v-card class="mx-1 my-5" rounded="lg" elevation="9">
          <v-card-title>{{ index + 1 }}-{{ room.name }}</v-card-title>
          <v-card-text>
            <v-expansion-panels :accordion="true" v-if="userInfo">
              <payments v-if="userInfo.admin" :roomIndex="room.id" />
              <roomsExtantion :roomIndex="room.id" />
              <soundSystems :roomIndex="room.id" />
              <quesLines :roomIndex="room.id" />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import roomsExtantion from "@/components/ROOMS/ExtantionView/rooms_in_quest";
import payments from "@/components/ROOMS/ExtantionView/payments";
import soundSystems from "@/components/ROOMS/ExtantionView/soundSystems";
import quesLines from "@/components/ROOMS/ExtantionView/quesLines";

export default {
  name: "Rooms",

  data: () => ({
    masterWord: "admin",
    monthSumVal: 0,
    showOnlyNotPayed: false,
    VroomSearch: "",
  }),
  async created() {
    await this.$store.dispatch("loading", true);
    await this.getRooms();
    // await this.getFromLocal();
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading", "ROOMS", "masterCode", "roomSearch", "userInfo"]),
    filteredRooms() {
      let results = this.ROOMS.filter((r) => {
        if (r.pay_way === "monthly") {
          return r;
        }
      });
      if (this.showOnlyNotPayed) {
        results = results.filter((res) => {
          if (res.payed === false || !res.payed) {
            return res;
          }
        });
      }
      if (this.roomSearch === "" || !this.roomSearch) {
        return results;
      } else {
        results = this.ROOMS.filter((r) => {
          if (r.name.toUpperCase().includes(this.roomSearch.toUpperCase())) {
            return r;
          }
        });
        return results;
      }
    },
    monthSum() {
      this.filteredRooms;
      this.ROOMS;
      // if (this.masterCode === this.masterWord) {
      let d = new Date();
      let currentMonth = d.getMonth() + 1;
      let currentYear = d.getFullYear();
      let sum = 0;
      this.filteredRooms.forEach((room) => {
        if (room.payments) {
          room.payments.forEach((payment) => {
            if (
              parseInt(payment.year) === currentYear &&
              parseInt(payment.month) === currentMonth
            ) {
              sum = sum + parseInt(payment.sum);
            }
          });
        }
      });
      // this.monthSumVal = Math.round(sum * 100) / 100;
      // }
      return sum;
    },
    masterAccess() {
      if (this.masterCode === "admin") {
        return true;
      }
      return false;
    },
  },
  components: {
    roomsExtantion,
    payments,
    soundSystems,
    quesLines,
  },
  methods: {
    async getRooms() {
      this.$store.commit("ROOMS", null);
      let rooms = await this.$store.dispatch("GET_AllTableElements", {
        msg: 'ROOMS WHERE pay_way="monthly"',
        var: "ROOMS",
      });
      if (rooms) {
        this.$store.commit("ROOMS", rooms);
      }

      console.log("ROOM", this.ROOMS);
    },
    // monthSum() {
    //   // if (this.masterCode === this.masterWord) {
    //   let d = new Date();
    //   let currentMonth = d.getMonth() + 1;
    //   let currentYear = d.getFullYear();
    //   let sum = 0;
    //   this.filteredRooms.forEach((room) => {
    //     if (room.payments) {
    //       room.payments.forEach((payment) => {
    //         if (
    //           parseInt(payment.year) === currentYear &&
    //           parseInt(payment.month) === currentMonth
    //         ) {
    //           sum = sum + parseInt(payment.sum);
    //         }
    //       });
    //     }
    //   });
    //   this.monthSumVal = Math.round(sum * 100) / 100;
    //   // }
    //   return "0";
    // },
  },
  watch: {
    VroomSearch() {
      this.$store.commit("roomSearch", this.VroomSearch);
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0px 3px 8px 3px;
}
.v-expansion-panel--active {
  background-color: #1976d2 !important;
  color: white !important;
}
</style>
