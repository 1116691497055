<template>
  <div>
    <v-data-table :headers="modifiedHeaders" :items="items">
      <template v-slot:item.dialog="{ item }">
        <v-btn @click="openDialog(item)">Open Dialog</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showDialog" max-width="80vw" v-if="currentItem">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">Open Dialog</v-btn>
      </template>
      <v-card>
        <!-- <v-card-title> -->
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title> EDIT</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- </v-card-title> -->
        <v-card-text v-if="component != null">
          <componentBuilder :key="component.id" :recivedComponent="component"/>
          <!-- <div v-for="field in component.result" :key="field.id"> -->
            <!-- <v-text-field
              v-if="field.type === 'text' && field.label != 'component'"
              :label="field.label"
              :value="field.value"
            ></v-text-field>
            <v-select
              v-model="currentItem[field.label]"
              :items="field.options"
              v-if="field.type === 'select' && field.label != 'component'"
              :label="field.label"
              :value="field.value"
            ></v-select> -->
          <!-- </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import componentBuilder from "@/components/PCB/componentsCreator/componentBuilder.vue";

export default {
  // components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    allFields: null,
    components: null,
  },
  computed: {
    modifiedHeaders() {
      return this.headers.concat([{ text: "Action", value: "dialog" }]);
    },
  },
  data: () => ({
    showDialog: false,
    currentItem: null,
    component: null,
  }),
  created() {
    // console.log("this.components", JSON.stringify(this.components));
  },
  methods: {
    async openDialog(item) {
      // this.getFieldOptions(this.allFields, "-NKZMqqSb0I9lr7ZjoZm");
      this.showDialog = true;
      this.currentItem = item;
      await this.getComponent();
      console.log("this.currentItem", this.currentItem);
    },
    // save() {
    //   let d = new Date();
    //   console.log({ fields: this.currentItem, timestamp: d.toString() });
    // },
    findFieldIDByItemLabel(item, label) {
      let component = this.components.find((o) => o.id === item.id);

      if (component && component.fields) {
        const array = Object.values(component.fields);
        let field = array.find((f) => f.label === label);
        let options = this.getFieldOptions(this.allFields, field.id);
        // if (options.length>0) {
        //   com
        // }
        return options;
      }
      return [];
    },
    async getComponent() {
      let res = null;
      if (this.currentItem != {}) {
        // res = this.components.find((o) => o.id === this.currentItem.id);
        const getObjectById = (array, objectId) => {
          for (const obj of array) {
            if (obj.id === objectId) {
              return obj;
            }
          }
          return null;
        };
        // console.log("this.components", JSON.stringify(this.components));
        console.log("this.currentItem.id", this.currentItem.id);
        res = getObjectById(this.components, this.currentItem.id);
        console.log("res", res);
        // for (const componentFieldKey in res.fields) {
        //   if (Object.hasOwnProperty.call(res.fields, componentFieldKey)) {
        //     const componentField = res.fields[componentFieldKey];
        //     let options = await this.getFieldOptions(
        //       this.allFields,
        //       componentField.id
        //     );
        //     console.log("componentField.label", componentField.label);
        //     console.log("it's options:", options);
        //     if (options.length > 0) {
        //       res.fields[componentFieldKey]["options"] = options;
        //       res.fields[componentFieldKey]["type"] = "select";
        //     } else {
        //       res.fields[componentFieldKey]["type"] = "text";
        //     }
        //   }
        // }
      }
      this.component = res;
    },
    // async getFieldOptions(object, fieldId) {
    //   if (object.fields) {
    //     if (object.fields[fieldId]) {
    //       const field = object.fields[fieldId];
    //       if (field.type === "select") {
    //         const options = [];
    //         for (const optionKey in field.options) {
    //           options.push(field.options[optionKey].value);
    //         }
    //         console.log("options", options);
    //         return options;
    //       }
    //     } else {
    //       let res = [];
    //       for (const fieldKey in object.fields) {
    //         if (Object.hasOwnProperty.call(object.fields, fieldKey)) {
    //           const oneField = object.fields[fieldKey];
    //           if (oneField.type === "select") {
    //             for (const optionKey in oneField.options) {
    //               if (Object.hasOwnProperty.call(oneField.options, optionKey)) {
    //                 const oneOption = oneField.options[optionKey];
    //                 res = await this.getFieldOptions(oneOption, fieldId);
    //                 if (res.length > 0) {
    //                   console.log("res from getFieldOptions", res);
    //                   return res;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return [];
    // },
  },
  components: {
    componentBuilder,
  },
};
</script>

<style>
</style>
