<template>
  <div>
    <v-data-table
      v-if="working_hours_computed && headers"
      :headers="headers"
      :items="working_hours_computed"
      :items-per-page="-1"
      class="elevation-3 mx-5"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" lg="2">
              <v-toolbar-title
                class="d-flex flex-column align-center justify-center"
              >
                WORKING HOURS
                <span v-if="!master"> | {{ userName }}</span>
              </v-toolbar-title>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="d-flex flex-column align-center justify-center">
                <v-subheader> TOTAL </v-subheader>
                <span class="text-h3 font-weight-black red--text">
                  {{ Math.round((sum + arrive_fee) * 100) / 100 }}
                  <v-icon small>mdi-currency-ils</v-icon>
                </span>
                <v-subheader> ({{ sum }} + {{ arrive_fee }}) </v-subheader>
              </div>
            </v-col>
            <v-col cols="12" lg="2">
              <v-select
                v-if="!inGroup"
                v-model="selectedYear_local"
                :hint="'select year'"
                :items="allYears"
                :label="'select year'"
                persistent-hint
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" lg="2">
              <v-select
                v-if="!inGroup"
                v-model="selectedMonth_local"
                :hint="'select month'"
                :items="allMonths"
                :item-text="'name'"
                :item-value="'index'"
                :label="'select month'"
                persistent-hint
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" lg="2">
              <div>
                <newRecord
                  v-if="master"
                  :projectID="projectID"
                  :uid="uid_computed"
                />
              </div>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
          </v-row>
        </v-container>
        <!-- </v-toolbar> -->
      </template>
      <template v-slot:item.current_working_from_home="{ item }">
        <v-simple-checkbox
          v-if="item.current_working_from_home != null"
          :value="item.current_working_from_home"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.hours="{ item }">
        {{ hours(item.hours) }}
      </template>
      <template v-if="master" v-slot:item.edit="{ item }">
        <editRecord :record="item" :projectID="projectID" />
      </template>
      <template v-slot:footer>
        <div class="ma-3" v-if="master">
          <v-divider class="mb-5"></v-divider>
          <v-btn @click="copy" color="primary"> LINK FOR CLIENT </v-btn>
          <div>
            https://b2b.playflow.io/oneProjectRecords?projectID={{
              projectID
            }}&uid={{ uid_computed }}
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
import { sortByMixin } from "@/mixins/sortByVuexMixin.js";

import newRecord from "@/components/modals/newRecord";
import editRecord from "@/components/modals/editRecord";

export default {
  props: {
    projectID: null,
    uid: null,
    master: {
      default: true,
    },
    selectedMonth: {
      default: new Date().getMonth() + 1,
    },
    selectedYear: {
      default: new Date().getFullYear(),
    },
    inGroup: {
      default: false,
    },
  },
  data: () => ({
    working_hours: null,
    headers: null,
    sortBy: sortByMixin,
    allMonths: [
      { index: 1, name: "January" },
      { index: 2, name: "February" },
      { index: 3, name: "March" },
      { index: 4, name: "April" },
      { index: 5, name: "May" },
      { index: 6, name: "June" },
      { index: 7, name: "July" },
      { index: 8, name: "August" },
      { index: 9, name: "September" },
      { index: 10, name: "October" },
      { index: 11, name: "November" },
      { index: 12, name: "December" },
    ],
    allYears: [2022, 2023],
    userName: "",
    selectedMonth_local: null,
    selectedYear_local: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
    uid_computed() {
      if (this.uid) {
        return this.uid;
      }
      if (this.UID) {
        return this.UID;
      }
      return null;
    },
    // selectedMonth_computed:{
    //   get: function(){
    //     return this.selectedMonth
    //   }
    // },
    working_hours_computed() {
      this.selectedMonth_local;
      this.selectedYear_local;
      if (this.working_hours) {
        let res = this.working_hours.filter((rec) => {
          if (
            new Date(rec.start_timestemp).getMonth() + 1 ===
              this.selectedMonth_local &&
            new Date(rec.start_timestemp).getFullYear() ===
              this.selectedYear_local
          ) {
            return rec;
          }
        });
        return res;
      }
      return null;
    },
    sum() {
      let ret = 0;
      if (this.working_hours_computed) {
        this.working_hours_computed.forEach((element) => {
          ret += element.sum;
        });
      }
      return Math.round(ret * 100) / 100;
    },
    arrive_fee() {
      let ret = 0;
      let last_computed_day = -1;
      if (this.working_hours_computed) {
        this.working_hours_computed.forEach((element) => {
          if (element.arrive_price > 0 && !element.current_working_from_home) {
            let d = new Date(element.start_timestemp).getDate();
            if (last_computed_day != d) {
              last_computed_day = d;
              ret += element.arrive_price;
            }
          }
        });
      }
      return ret;
    },
  },
  async created() {
    this.selectedMonth_local = this.selectedMonth;
    this.selectedYear_local = this.selectedYear;
    await this.loadRecords();
    if (!this.master) {
      let viewMessage = this;
      let itemsRef = await fire
        .database()
        .ref(`users/${viewMessage.uid_computed}/info/`);
      await itemsRef.on("value", async (snapshot) => {
        let data = snapshot.val();
        if (data) {
          viewMessage.userName = data.name;
        }
      });
    }
  },
  methods: {
    addZero(payload) {
      if (parseInt(payload) < 10) {
        return `0${payload}`;
      }
      return `${payload}`;
    },
    hours(sec) {
      if (sec) {
        let s = Math.round(sec % 60);
        let m = Math.round((sec % 3600) / 60);
        let h = Math.round(sec / 3600);
        return `${this.addZero(h)}:${this.addZero(m)}:${this.addZero(s)}`;
      }
      return "";
    },
    copy() {
      let s = `https://b2b.playflow.io/oneProjectRecords?projectID=${this.projectID}&uid=${this.uid_computed}`;
      navigator.clipboard.writeText(s);
    },
    async loadRecords() {
      let viewMessage = this;
      let itemsRef = await fire
        .database()
        .ref(`projects/${viewMessage.projectID}/working_hours/`)
        .orderByChild("uid")
        .equalTo(this.uid_computed);
      await itemsRef.on("value", async (snapshot) => {
        let data = snapshot.val();
        if (data) {
          viewMessage.working_hours = null;
          let res = Object.keys(data).map((key) => {
            let obj = {
              ...data[key],
              start_day:
                `${new Date(
                  data[key].start_timestemp
                ).getFullYear()}-${this.addZero(
                  new Date(data[key].start_timestemp).getMonth() + 1
                )}-${this.addZero(
                  new Date(data[key].start_timestemp).getDate()
                )}` || "",
              // sum:
              //   Math.round(
              //     ((new Date(data[key].stop_timestemp) -
              //       new Date(data[key].start_timestemp)) /
              //       3600000) *
              //       data[key].per_hour *
              //       100
              //   ) / 100,
              id: key,
            };
            if (!data[key].service) {
              let d = new Date(data[key].stop_timestemp);
              if (d instanceof Date && !isNaN(d)) {
                obj.stop_time = `${this.addZero(
                  new Date(data[key].stop_timestemp).getHours()
                )}:${this.addZero(
                  new Date(data[key].stop_timestemp).getMinutes()
                )}`;
              }
              d = new Date(data[key].start_timestemp);
              if (d instanceof Date && !isNaN(d)) {
                obj.start_time = `${this.addZero(
                  new Date(data[key].start_timestemp).getHours()
                )}:${this.addZero(
                  new Date(data[key].start_timestemp).getMinutes()
                )}`;
              }
              obj.hours = Math.round(
                (new Date(data[key].stop_timestemp) -
                  new Date(data[key].start_timestemp)) /
                  1000
              );
              obj.sum =
                Math.round(
                  ((new Date(data[key].stop_timestemp) -
                    new Date(data[key].start_timestemp)) /
                    3600000) *
                    data[key].per_hour *
                    100
                ) / 100;
            } else {
              obj.sum = data[key].service_fee;
              obj.per_hour = "";
              obj.current_working_from_home = null;
            }
            return obj;
          });
          viewMessage.working_hours = this.sortRecord(res);

          viewMessage.headers = [
            {
              value: "start_day",
              text: "Date",
            },
            {
              value: "start_time",
              text: "Start",
            },
            {
              value: "stop_time",
              text: "Stop",
            },
            {
              value: "hours",
              text: "Hours",
            },
            {
              value: "per_hour",
              text: "Hour Fee (NIS/Hour)",
            },

            {
              value: "sum",
              text: "Total (NIS)",
            },
            {
              value: "current_working_from_home",
              text: "From Home",
            },
            {
              value: "description",
              text: "Description",
            },
          ];

          if (viewMessage.master) {
            viewMessage.headers.push({
              text: "Edit",
              value: "edit",
              sortable: false,
            });
          }

          // console.log("working_hours", viewMessage.working_hours);
        }
      });
    },
    sortRecord(records) {
      records = this.sortBy(records, {
        prop: "start_timestemp",
        desc: false,
        parser: function (item) {
          return new Date(item);
        },
      });
      return records;
    },
  },
  watch: {
    sum() {
      this.$emit("sum", {
        id: this.projectID,
        sum: this.sum + this.arrive_fee,
      });
    },
    selectedMonth() {
      this.selectedMonth_local = this.selectedMonth;
    },
    selectedYear() {
      this.selectedYear_local = this.selectedYear;
    },
  },
  components: {
    newRecord,
    editRecord,
  },
};
</script>

<style>
</style>