<template>
  <v-select
    dense
    class="ma-3 pa-3"
    v-if="allComponents"
    v-model="selectedComponent"
    :hint="'select component'"
    :items="allComponents"
    item-text="value"
    item-value="id"
    :label="'select component'"
    persistent-hint
    return-object
    single-line
    clearable
  >
    <newComponent slot="append-outer" v-if="!justSelect"/>
    <newComponent
      :key="selectedComponent.id"
      v-if="selectedComponent && !justSelect"
      :component="selectedComponent"
      slot="prepend"
    />
  </v-select>
</template>

<script>
import fire from "@/main";
import newComponent from "@/components/PCB/componentSketches/newComponent";

export default {
  props: {
    justSelect: {
      default: false,
    },
  },
  data: () => ({
    allComponents: null,
    selectedComponent: null,
    // key_gen: 1,
  }),
  async created() {
    let viewMessage = this;
    let itemsRef = await fire
      .database()
      .ref(`PCB/components/fields/ID1/options`);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.allComponents = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        }));
      }
    });
  },
  watch: {
    selectedComponent() {
      this.$emit("selectedComponentFunc", this.selectedComponent);
    },
  },
  components: {
    newComponent,
  },
};
</script>

<style>
</style>