<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      v-if="project"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="primary" v-bind="attrs" v-on="on">
          {{ project.name }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <!-- <v-btn icon dark text @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn> -->
          <v-btn dark icon @click="remove">
            <v-icon color="error">mdi-delete-forever</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> {{ project.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  props: {
    group: null,
    project: null,
  },
  data: () => ({
    dialog: false,
  }),
  components: {},
  created() {},
  computed: {},
  methods: {
    async save() {
      this.dialog = false;
    },
    async remove() {
      await fire
        .database()
        .ref(`groups/${this.group.id}/projects/${this.project.id}/`)
        .set(null);
    },
  },
};
</script>

<style>
</style>