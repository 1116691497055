import fire from "@/main";
import { mapGetters } from "vuex";

export const loadGlobalsMixin = {
    data: () => ({
        all_users: [],
        all_projects: [],
        all_my_projects: [],
        all_groups: [],
        all_my_groups: [],
        loading_mixin: false,
    }),
    computed: {
        ...mapGetters(["UID"]),
    },
    methods: {
        async loadUsers() {
            let viewMessage = this;
            let itemsRef = await fire.database().ref(`users/`);
            await itemsRef.on("value", async (snapshot) => {
                let data = snapshot.val();
                if (data) {
                    viewMessage.loading_mixin = true;
                    let arr_tmp = [];
                    let keys_arr = Object.keys(data);
                    for (let key of keys_arr) {
                        let arr_tmp_ready = {
                            id: key,
                            ...data[key],
                        };
                        let ok = true;
                        let exist = null;
                        if (viewMessage.all_users.length > 0) {
                            exist = viewMessage.all_users.filter((p) => {
                                if (p.id === key) {
                                    return p;
                                }
                            });
                            if (exist && exist.length > 0) {
                                ok = false;
                            }
                        }
                        if (ok) {
                            arr_tmp.push(arr_tmp_ready);
                        }
                    }
                    viewMessage.all_users = [...viewMessage.all_users, ...arr_tmp];
                    // console.log("all_users", this.all_users);
                    viewMessage.loading_mixin = false;
                }
            });

        },
        async loadProjects() {
            let viewMessage = this;
            let itemsRef = await fire.database().ref(`projects/`);
            await itemsRef.on("value", async (snapshot) => {
                let data = snapshot.val();
                if (data) {
                    viewMessage.loading_mixin = true;
                    let arr_tmp = [];
                    let keys_arr = Object.keys(data);
                    for (let key of keys_arr) {
                        let arr_tmp_ready = {
                            id: key,
                            ...data[key],
                        };
                        let ok = true;
                        // let exist = null;
                        if (viewMessage.all_projects.length > 0) {
                            viewMessage.all_projects.forEach((p, index) => {
                                if (p.id === key) {
                                    viewMessage.all_projects[index] = arr_tmp_ready;
                                    ok = false;
                                }
                            });
                        }
                        if (ok) {
                            arr_tmp.push(arr_tmp_ready);
                        }
                    }
                    viewMessage.all_projects = [...viewMessage.all_projects, ...arr_tmp];
                    // console.log("projects", this.all_projects);
                    viewMessage.loading_mixin = false;
                }
            });
        },
        async loadGroups() {
            let viewMessage = this;
            let itemsRef = await fire.database().ref(`groups/`);
            await itemsRef.on("value", async (snapshot) => {
                let data = snapshot.val();
                if (data) {
                    viewMessage.loading_mixin = true;
                    let arr_tmp = [];
                    let keys_arr = Object.keys(data);
                    for (let key of keys_arr) {
                        let arr_tmp_ready = {
                            id: key,
                            ...data[key],
                        };
                        let ok = true;
                        // let exist = null;
                        if (viewMessage.all_groups.length > 0) {
                            viewMessage.all_groups.forEach((p, index) => {
                                if (p.id === key) {
                                    viewMessage.all_groups[index] = arr_tmp_ready;
                                    ok = false;
                                }
                            });
                        }
                        if (ok) {
                            arr_tmp.push(arr_tmp_ready);
                        }
                    }
                    viewMessage.all_groups = [...viewMessage.all_groups, ...arr_tmp];
                    // console.log("groups", this.all_groups);
                    viewMessage.loading_mixin = false;
                }
            });

        },
        getMyProjects() {
            //sorting projects for current user
            let new_arr = this.all_projects.filter((project_tmp) => {
                if (project_tmp.per_hour) {
                    let users = Object.keys(project_tmp.per_hour).map((key) => ({
                        ...project_tmp.per_hour[key],
                        id: key,
                    }));
                    let mine_project = false;
                    for (const key in users) {
                        if (Object.hasOwnProperty.call(users, key)) {
                            const user = users[key];
                            if (user.uid === this.UID) {
                                mine_project = true;
                            }
                        }
                    }
                    if (mine_project === true) {
                        return project_tmp;
                    }
                }
            });
            this.all_my_projects = new_arr;
        },
        getMyGroups() {
            //sorting projects for current user
            let new_arr = this.all_groups.filter((group_tmp) => {
                if (group_tmp.projects) {
                    let projects = Object.keys(group_tmp.projects).map((key) => ({
                        ...group_tmp.projects[key],
                        id: key,
                    }));
                    let mine_group = false;
                    for (const key in projects) {
                        if (Object.hasOwnProperty.call(projects, key)) {
                            const project = projects[key];
                            if (project.uid === this.UID) {
                                mine_group = true;
                            }
                        }
                    }
                    if (mine_group === true) {
                        return group_tmp;
                    }
                }
            });
            this.all_my_groups = new_arr;
        }
    },
    watch: {
        all_projects() {
            this.getMyProjects()
        },
        all_groups() {
            this.getMyGroups()
        }
    }
}