<template>
  <div>
    <v-progress-linear
      color="indigo"
      height="10"
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <div v-if="!loading">
      <div class="ma-1" v-for="(room, index) in filteredRooms" :key="room.id">
        <v-card class="mx-15 my-5" rounded="lg" elevation="9">
          <v-card-title>{{ index + 1 }}-{{ room.name }}</v-card-title>
          <v-card-text>
            <v-expansion-panels :accordion="true" v-if="userInfo">
              <roomsExtantion :roomIndex="room.id" />
              <soundSystems :roomIndex="room.id" />
              <quesLines :roomIndex="room.id" />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import roomsExtantion from "@/components/ROOMS/ExtantionView/rooms_in_quest";
import soundSystems from "@/components/ROOMS/ExtantionView/soundSystems";
import quesLines from "@/components/ROOMS/ExtantionView/quesLines";

export default {
  name: "onePay",

  data: () => ({
    // searcRoomName: "",
    // masterCode: "",
  }),
  async created() {
    await this.$store.dispatch("loading", true);
    await this.getRooms();
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading", "ROOMS", "roomSearch", "userInfo"]),
    filteredRooms() {
      let results = this.ROOMS.filter((r) => {
        if (r.pay_way === "one pay") {
          return r;
        }
      });
      if (this.roomSearch === "" || !this.roomSearch) {
        return results;
      } else {
        results = this.ROOMS.filter((r) => {
          if (r.name.toUpperCase().includes(this.roomSearch.toUpperCase())) {
            return r;
          }
        });
        return results;
      }
    },
    masterAccess() {
      if (this.masterCode === "admin") {
        return true;
      }
      return false;
    },
  },
  components: {
    roomsExtantion,
    // payments,
    soundSystems,
    quesLines,
  },
  methods: {
    async getRooms() {
      this.$store.commit("ROOMS", null);
      let rooms = await this.$store.dispatch("GET_AllTableElements", {
        msg: 'ROOMS WHERE pay_way="one pay"',
        var: "ROOMS",
      });
      this.$store.commit("ROOMS", rooms);
    },
  },
};
</script>
