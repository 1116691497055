<template>
  <div>
    <div v-if="userInfo && userInfo.admin">
      <newGroup class="ma-3" />
      <div v-if="all_groups.length > 0">
        <div v-for="(group, index) in all_groups" :key="index" class="ma-1">
          <oneGroup :group="group" @remove="remove" :key="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

import newGroup from "@/components/modals/newGroup";
// import oneProject from "@/components/modals/oneProject";
import oneGroup from "@/components/modals/oneGroup";

export default {
  data: () => ({}),
  async created() {
    await this.loadGroups();
  },
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
  },
  methods: {
    remove() {
      this.all_groups = [];
      this.loadGroups();
    },
  },
  components: {
    newGroup,
    // oneProject,
    oneGroup,
  },
  mixins: [loadGlobalsMixin],
};
</script>
<style>
</style>