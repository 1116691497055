<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title> GROUPS </v-card-title>
            <v-card-text>
              <groups />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title> PROJECTS </v-card-title>
            <v-card-text>
              <projects />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import projects from "@/components/projects";
import groups from "@/components/groups";

export default {
  name: "Projects",

  components: {
    projects,
    groups,
  },
};
</script>
