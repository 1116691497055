<template>
  <v-expansion-panel @click="load">
    <v-expansion-panel-header>
      Quest Lines <span v-if="!loading">({{ quesLines.length }} quest lines)</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="loadingProgress">
      <v-progress-linear
        color="indigo"
        height="10"
        indeterminate
      ></v-progress-linear>
    </v-expansion-panel-content>
    <div v-if="!loading">
      <v-expansion-panel-content
        v-for="quesLine in quesLines"
        :key="quesLine.id"
      >
        <v-expansion-panels :accordion="true" >
          <v-expansion-panel>
            <v-expansion-panel-header
              :color="getColor(quesLine.lineQuest_order)"
            >
              <span
                >{{ quesLine.lineQuest_order }}.{{ quesLine.description }}
                <!-- ({{
                  soundSystem.soundSystems_players.length
                }}) -->
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="quest in quesLine.quests_in_questroom"
              :key="quest.id"
            >
              <!-- <span class="ml-3"> -->
              <v-card
                flat
                class="pl-3 mt-3 py-2 mx-3"
                :color="getColor(quest.order_in_questLine)"
                dark
              >
                {{ quest.order_in_questLine }}.{{ quest.name }}
              </v-card>
              <!-- </span> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </div>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    roomIndex: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
    loadingProgress: false,
    colors:["red","pink","purple","indigo","cyan","teal","light-green","amber","brown","blue-grey"],
  }),
  components: {
    // oneExtantionPAnel,
  },
  computed: {
    ...mapGetters(["ROOMS"]),
    quesLines() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      }).quesLines;
    },
    room() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      });
    },
  },
  methods: {
    async load() {
      if (this.loading) {
        this.loadingProgress = true;
        await this.putQuesLinesInRooms();
        await this.putQuests_in_questroomInQuesLines();
        this.loadingProgress = false;
        this.loading = false;
      }
    },
    async putQuesLinesInRooms() {
      let quesLines = await this.$store.dispatch("GET_AllTableElements", {
        msg: "quesLines WHERE room_id=" + this.room.id,
        var: "quesLines",
      });
      quesLines = quesLines.sort(function (a, b) {
        return a.lineQuest_order > b.lineQuest_order
          ? 1
          : b.lineQuest_order > a.lineQuest_order
          ? -1
          : 0;
      });
      this.room.quesLines = quesLines;
    },
    async putQuests_in_questroomInQuesLines() {
      let quests_in_questroom = await this.$store.dispatch(
        "GET_AllTableElements",
        {
          msg: "quests_in_questroom WHERE room_id=" + this.room.id,
          var: "quests_in_questroom",
        }
      );
      quests_in_questroom = quests_in_questroom.sort(function (a, b) {
        return a.order_in_questLine > b.order_in_questLine
          ? 1
          : b.order_in_questLine > a.order_in_questLine
          ? -1
          : 0;
      });
      this.quesLines.forEach((quesLine) => {
        let currentQuests = quests_in_questroom.filter((y) => {
          if (y.questLine === quesLine.creation_orde) {
            return y;
          }
        });
        if (currentQuests) {
          quesLine.quests_in_questroom = currentQuests;
        }
      });
    },
    getColor(order) {
      // console.log(order);
      let result = `${this.colors[(order-1)%this.colors.length]} lighten-2`;
      return result;
    },
  },
};
</script>

<style>
</style>