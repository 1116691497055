<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="pb-3"
          :color="component ? '' : 'green'"
          v-bind="attrs"
          v-on="on"
        >
          <!-- NEW -->
          <v-icon large v-if="!component">mdi-plus</v-icon>
          <v-icon v-if="component">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark v-if="!component" text @click="create"> CREATE </v-btn>
          <v-btn dark v-if="component" text @click="edit"> SAVE </v-btn>
          <v-btn dark v-if="component" text @click="remove"> DELETE </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title> New Component Sketch</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="Component Name"
            single-line
            v-model="name"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  props: {
    component: null,
  },
  data: () => ({
    dialog: false,
    name: null,
  }),
  created() {
    if (this.component) {
      this.name = this.component.value;
    }
  },
  methods: {
    async create() {
      if (this.name != "component") {
        await fire
          .database()
          .ref(`PCB/components/fields/ID1/options`)
          .push({ value: this.name });
        this.dialog = false;
      }
    },
    async edit() {
      if (this.name != "component") {
        await fire
          .database()
          .ref(`PCB/components/fields/ID1/options/${this.component.id}`)
          .update({ value: this.name });
        this.dialog = false;
      }
    },
    async remove() {
      await fire
        .database()
        .ref(`PCB/components/fields/ID1/options/${this.component.id}`)
        .set(null);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>