<template>
  <!-- <div> -->
  <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"
        ><v-icon large color="green">mdi-plus</v-icon></v-btn
      >
    </template>
    <v-card v-if="!loading_tmp">
      <v-toolbar dark color="primary">
        <v-btn v-if="selectedGroup" dark text @click="add"> ADD </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title> ADD GROUP</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-9">
        <v-select
          v-if="allGroupTypes.length > 0"
          v-model="selectedGroupType"
          :hint="'select group type'"
          :items="allGroupTypes"
          item-text="name"
          item-value="id"
          :label="'select group type'"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-select
          v-if="selectedGroupType && groupList.length > 0"
          v-model="selectedGroup"
          :hint="'select group'"
          :items="groupList"
          item-text="name"
          item-value="id"
          :label="'select group'"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";
// import { loadGlobalsMixin } from "@/mixins/loadGlobalsMixin.js";

export default {
  props: {
    record: null,
    allGroups: null,
    allGroupTypes: null,
  },
  data: () => ({
    loading_tmp: false,
    dialog: false,
    selectedGroupType: null,
    selectedGroup: null,
  }),
  computed: {
    ...mapGetters(["UID"]),
    groupList() {
      if (this.selectedGroupType) {
        if (this.selectedGroupType.groups) {
          if (this.selectedGroupType.groups.value) {
            // let res = this.selectedGroupType.groups.value.find(
            //   (g) => g.id === this.selectedGroupType.id
            // );
            return this.selectedGroupType.groups.value;
          }
        }
      }
      return [];
    },
  },
  async created() {},
  methods: {
    async add() {
      await fire
        .database()
        .ref(`todo/users/${this.UID}/records/${this.record.id}/groups`)
        .push({
          group_id: this.selectedGroup.id,
        });
      console.log("adding group");
      this.dialog = false;
    },
  },
  watch: {
    selectedGroupType() {
      this.selectedGroup = null;
    },
  },
};
</script>

<style>
</style>