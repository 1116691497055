<template>
  <oneProjectRecords :projectID="projectID" :uid="uid" :master="false"/>
</template>

<script>
import oneProjectRecords from "@/components/records/oneProjectRecords";
export default {
    components:{
        oneProjectRecords
    },
    created(){
        console.log(this.$route.query);
    },
    computed:{
        projectID(){
            if(this.$route.query.projectID){
                return this.$route.query.projectID
            }
            return ''
        },
        uid(){
            if(this.$route.query.uid){
                return this.$route.query.uid
            }
            return ''
        }
    }

}
</script>

<style>

</style>