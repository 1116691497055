<template>
  <v-expansion-panel @click="load">
    <v-expansion-panel-header>
      SPEAKERS <span v-if="!loading">({{ soundSystems.length }} speakers)</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="loadingProgress">
      <v-progress-linear
        color="indigo"
        height="10"
        indeterminate
      ></v-progress-linear>
    </v-expansion-panel-content>
    <div v-if="!loading">
      <v-expansion-panel-content
        v-for="soundSystem in soundSystems"
        :key="soundSystem.id"
      >
        <v-expansion-panels :accordion="true" >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span
                >{{ soundSystem.name }} ({{
                  soundSystem.soundSystems_players.length
                }})</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="musicPlayerlayer in soundSystem.soundSystems_players"
              :key="musicPlayerlayer.id"
            >
              {{ musicPlayerlayer.name }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </div>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    roomIndex: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
    loadingProgress: false,
  }),
  components: {
    // oneExtantionPAnel,
  },
  computed: {
    ...mapGetters(["ROOMS"]),
    soundSystems() {
      return this.ROOMS.find((r)=>{if(r.id===this.roomIndex) return r}).soundSystems;
    },
    room() {
      return this.ROOMS.find((r)=>{if(r.id===this.roomIndex) return r});
    },
  },
  methods: {
    async load() {
      if (this.loading) {
        this.loadingProgress = true;
        await this.putSoundSystemsInRooms();
        await this.putSoundSystems_playersInSoundSystems();
        this.loadingProgress = false;
        this.loading = false;
      }
    },
    async putSoundSystemsInRooms() {
      let soundSystems = await this.$store.dispatch("GET_AllTableElements", {
        msg: "soundSystems WHERE room_id=" + this.room.id,
        var: "soundSystems",
      });
      this.room.soundSystems = soundSystems;
    },
    async putSoundSystems_playersInSoundSystems() {
      let soundSystems_players = await this.$store.dispatch(
        "GET_AllTableElements",
        {
          msg: "soundSystems_players WHERE room_id=" + this.room.id,
          var: "soundSystems_players",
        }
      );
      this.soundSystems.forEach((soundSystem) => {
        let currentSoundPlayer = soundSystems_players.filter((y) => {
          if (y.soundSystems === soundSystem.id) {
            return y;
          }
        });
        if (currentSoundPlayer) {
          soundSystem.soundSystems_players = currentSoundPlayer;
        }
      });
    },
  },
};
</script>

<style>
</style>