<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <!-- NEW Field -->
          <v-icon color="green"  v-if="!field">mdi-plus</v-icon>
          <v-icon v-if="field">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn v-if="!field" dark text @click="create"> CREATE </v-btn>
          <v-btn v-if="field" dark text @click="save"> SAVE </v-btn>
          <v-btn v-if="field" dark text @click="remove"> DELETE </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="!field"> New Field</v-toolbar-title>
          <v-toolbar-title v-if="!field"> Edit Field</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="Field Name"
            single-line
            v-model="name"
          ></v-text-field>
          <v-select
            v-model="selectedField"
            :items="fieldTypes"
            v-if="fieldTypes"
          >
          </v-select>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  props: {
    relativePath: {
      default: "",
    },
    field: null,
  },
  data: () => ({
    dialog: false,
    name: null,
    selectedField: null,
    fieldTypes: null,
    // myPath: "",
  }),
  async created() {
    if (this.field) {
      this.name = this.field.label;
      this.selectedField = this.field.type;
    }
    let viewMessage = this;
    let itemsRef = await fire
      .database()
      .ref(`PCB/components/componentFieldTypes`);
    await itemsRef.on("value", async (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.fieldTypes = data;
      }
    });
  },
  methods: {
    async create() {
      await fire
        .database()
        .ref(`${this.relativePath}/fields`)
        .push({ label: this.name, type: this.selectedField });
      this.dialog = false;
    },
    async save() {
      console.log(this.relativePath);
      await fire
        .database()
        .ref(`${this.relativePath}`)
        .update({ label: this.name, type: this.selectedField });
      this.dialog = false;
    },
    async remove(){
      await fire
        .database()
        .ref(`${this.relativePath}`)
        .set(null);
      this.dialog = false;
    }
  },
};
</script>

<style>
</style>