<template>
  <v-expansion-panel @click="load">
    <v-expansion-panel-header v-if="!loading">
      SPACES ({{ filteredSpaces.length }} spaces)</v-expansion-panel-header
    >
    <v-expansion-panel-header v-if="loading"> SPACES </v-expansion-panel-header>
    <v-expansion-panel-content v-if="loadingProgress">
      <v-progress-linear
        color="indigo"
        height="10"
        indeterminate
      ></v-progress-linear>
    </v-expansion-panel-content>

    <v-expansion-panel-content>
      <v-text-field
        v-model="elementSearch"
        label="serch by element name"
        append-icon="mdi-magnify"
        filled
        rounded
        clearable
        class="mx-3"
      ></v-text-field>
    </v-expansion-panel-content>

    <div v-if="!loading">
      <v-expansion-panel-content>
        <v-expansion-panels :accordion="true">
          <v-expansion-panel>
            <v-expansion-panel-header>
              TOPICS ({{ filteredTopics.length }})
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="topic in filteredTopics"
              :key="topic"
            >
              <v-expansion-panels :accordion="true">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ topic }}
                    <span v-if="!elementSearch || elementSearch === ''">
                      (last pin is
                      {{
                        countPins(filteredBySearch(getElementsByTopic(topic)))
                      }}
                      )
                    </span>
                    <span v-else>
                      (
                      {{ filteredBySearch(getElementsByTopic(topic)).length }}
                      elements)
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-for="component in filteredBySearch(
                      getElementsByTopic(topic)
                    )"
                    :key="component.id"
                  >
                    <v-card flat class="ml-3">
                      {{ component.pin }} | {{ component.name }}
                      <elementInfo :component="component" />
                      <!-- <v-btn icon @click="componentInfo(component)"><v-icon > mdi-information-variant</v-icon></v-btn> -->
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </div>
    <div v-if="!loading">
      <v-expansion-panel-content
        v-for="space in filteredSpaces"
        :key="space.id"
      >
        <v-expansion-panels :accordion="true">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>{{ space.room_name }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <oneExtantionPAnel
                :header="'INPUTS'"
                :components="filteredBySearch(space.buttons_in_quest)"
              />
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <oneExtantionPAnel
                :header="'OUTPUTS'"
                :components="filteredBySearch(space.relay_elements)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </div>
  </v-expansion-panel>
</template>

<script>
import oneExtantionPAnel from "@/components/ROOMS/common/oneExtantionPAnel";
import elementInfo from "@/components/ROOMS/ModalWindow/elementInfo";
import { mapGetters } from "vuex";

export default {
  props: {
    roomIndex: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
    loadingProgress: false,
    elementSearch: "",
  }),
  components: {
    oneExtantionPAnel,
    elementInfo,
  },
  computed: {
    ...mapGetters(["ROOMS", "roomSearch"]),
    rooms_in_quest() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      }).rooms_in_quest;
    },
    room() {
      return this.ROOMS.find((r) => {
        if (r.id === this.roomIndex) return r;
      });
    },
    topics() {
      let arr = [];
      this.room.buttons_in_quest.forEach((button) => {
        if (!arr.includes(button.index_letter)) {
          arr.push(button.index_letter);
        }
      });
      this.room.relay_elements.forEach((relay) => {
        if (!arr.includes(relay.index_letter)) {
          arr.push(relay.index_letter);
        }
      });
      return arr;
    },
    filteredTopics() {
      let results = this.topics.filter((r) => {
        if (this.filteredBySearch(this.getElementsByTopic(r)).length) {
          return r;
        }
      });
      return results;
    },
    filteredSpaces() {
      if (this.roomSearch === "" || !this.roomSearch) {
        return this.rooms_in_quest;
      }
      let results = this.rooms_in_quest.filter((currentRoom) => {
        let inputs = this.filteredBySearch(currentRoom.buttons_in_quest);
        let outputs = this.filteredBySearch(currentRoom.relay_elements);
        if (inputs.length || outputs.length) {
          return currentRoom;
        }
      });
      return results;
    },
  },
  methods: {
    async load() {
      if (this.loading) {
        this.loadingProgress = true;
        await this.putRooms_in_questInRooms();
        await this.putButtons_in_questInRooms();
        await this.putRelay_elementsInRooms();
        this.loadingProgress = false;
        this.loading = false;
      }
    },
    filteredBySearch(arr) {
      if (this.elementSearch === "" || !this.elementSearch) {
        return arr;
      }
      if (!arr) {
        return arr;
      }
      let results = arr.filter((r) => {
        if (r) {
          if (r.name.toUpperCase().includes(this.elementSearch.toUpperCase())) {
            return r;
          }
        }
      });
      return results;
    },
    async putRooms_in_questInRooms() {
      let rooms_in_quest = await this.$store.dispatch("GET_AllTableElements", {
        msg: "rooms_in_quest WHERE room_id=" + this.room.id,
        var: "rooms_in_quest",
      });
      this.room.rooms_in_quest = rooms_in_quest;
    },
    async putButtons_in_questInRooms() {
      let buttons_in_quest = await this.$store.dispatch(
        "GET_AllTableElements",
        {
          msg: "buttons_in_quest  WHERE room_id=" + this.room.id,
          var: "buttons_in_quest",
        }
      );
      this.room.buttons_in_quest = buttons_in_quest;
      if (this.room.rooms_in_quest) {
        this.room.rooms_in_quest.forEach((space) => {
          let currentInputs = buttons_in_quest.filter((y) => {
            if (y.roomNum === space.id) {
              return y;
            }
          });
          if (currentInputs) {
            space.buttons_in_quest = currentInputs;
          }
        });
      }
    },
    async putRelay_elementsInRooms() {
      let relay_elements = await this.$store.dispatch("GET_AllTableElements", {
        msg: "relay_elements   WHERE room_id=" + this.room.id,
        var: "relay_elements",
      });
      this.room.relay_elements = relay_elements;
      if (this.room.rooms_in_quest) {
        this.room.rooms_in_quest.forEach((space) => {
          let currentOutputs = relay_elements.filter((y) => {
            if (y.roomNum === space.id) {
              return y;
            }
          });
          if (currentOutputs) {
            space.relay_elements = currentOutputs;
          }
        });
      }
    },
    getElementsByTopic(currentTopic) {
      let arr = [];
      let btns = this.room.buttons_in_quest.filter((y) => {
        if (y.index_letter === currentTopic) {
          return y;
        }
      });
      let pin = 0;
      btns.forEach((r) => {
        r.pin = pin;
        pin = pin + parseInt(r.pins_number);
      });
      let rels = this.room.relay_elements.filter((y) => {
        if (y.index_letter === currentTopic) {
          return y;
        }
      });
      pin = 0;
      rels.forEach((r) => {
        r.pin = pin;
        pin = pin + parseInt(r.num_of_elements);
      });
      arr = arr.concat(rels, btns);
      // console.log({});
      return arr || [];
    },
    countPins(arr) {
      let sum = 0;
      arr.forEach((element) => {
        if (element.num_of_elements) {
          sum = sum + parseInt(element.num_of_elements);
        }
        if (element.pins_number) {
          sum = sum + parseInt(element.pins_number);
        }
      });
      return sum - 1;
    },
    // componentInfo(component){
    //   console.log(component);
    // }
  },
};
</script>

<style>
</style>