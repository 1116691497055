<template>
  <div>
    <v-card class="mx-3 mb-3" elevation="5">
    <v-card-title>
      <v-tabs color="primary" v-model="tab" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container fluid>
      <v-row>
        <v-col cols="12" lg="10">
          <records
            v-if="selectedGroup && group_types && allRecords"
            :selectedGroup="selectedGroup"
            :group_types="group_types"
            :group_type="lastSelectedGroupType"
            :allRecords="allRecords"
            @groupTypeSelected="groupTypeSelected"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <groupTypes
            v-if="group_types && allRecords"
            :group_types="group_types"
            :lastSelectedGroupType="lastSelectedGroupType"
            @groupSelected="groupSelected"
            @groupTypeSelected="groupTypeSelected"
            :allRecords="allRecords"
            :key="lastSelectedGroupType || 1"
          />
        </v-col>
      </v-row>
    </v-container>
        </v-tab-item>
        <v-tab-item>
          <records
            v-if="selectedGroup && group_types && allRecords"
            :selectedGroup="selectedGroup"
            :group_types="group_types"
            :group_type="lastSelectedGroupType"
            :allRecords="allRecords"
            @groupTypeSelected="groupTypeSelected"
            :showAllRecords="true"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>

    <!-- <v-container fluid>
      <v-row>
        <v-col cols="12" lg="10">
          <records
            v-if="selectedGroup && group_types && allRecords"
            :selectedGroup="selectedGroup"
            :group_types="group_types"
            :group_type="lastSelectedGroupType"
            :allRecords="allRecords"
            @groupTypeSelected="groupTypeSelected"
            :showAllRecords="true"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <groupTypes
            v-if="group_types && allRecords"
            :group_types="group_types"
            :lastSelectedGroupType="lastSelectedGroupType"
            @groupSelected="groupSelected"
            @groupTypeSelected="groupTypeSelected"
            :allRecords="allRecords"
            :key="lastSelectedGroupType || 1"
          />
        </v-col>
      </v-row>
    </v-container> -->
  </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

import groupTypes from "@/components/todo/groupTypes";
import records from "@/components/todo/records";
export default {
  name: "ToDo",
  data: () => ({
    userToDo: {},
    selectedGroup: null,
    allRecords: [],
    loading_tmp: false,
    tab: null,
    items: ["ToDo", "All Records"],
  }),
  computed: {
    ...mapGetters(["UID", "loading"]),
    group_types() {
      if (this.userToDo) {
        if (this.userToDo.group_types) {
          if (this.userToDo.group_types.value) {
            return this.userToDo.group_types.value;
          }
        }
      }
      return [];
    },
    lastSelectedGroupType() {
      if (!this.loading_tmp) {
        if (this.userToDo) {
          if (this.userToDo.group_types) {
            if (this.userToDo.group_types.lastSelectedGroupType) {
              return this.userToDo.group_types.lastSelectedGroupType;
            }
          }
        }
      }
      return "";
    },
    records_key() {
      if (this.selectedGroup) {
        return this.selectedGroup.id;
      }
      return 1;
    },
  },
  async created() {
    if (this.UID) {
      let viewMessage = this;
      let itemsRef = await fire.database().ref(`todo/users/${this.UID}`);
      await itemsRef.on("value", async (snapshot) => {
        let data = snapshot.val();
        if (data) {
          viewMessage.loading_tmp = true;
          viewMessage.userToDo = data;
          if (viewMessage.userToDo) {
            if (viewMessage.userToDo.group_types) {
              if (viewMessage.userToDo.group_types.value) {
                viewMessage.userToDo.group_types.value = Object.keys(
                  viewMessage.userToDo.group_types.value
                ).map((key) => ({
                  ...viewMessage.userToDo.group_types.value[key],
                  id: key,
                }));
                if (viewMessage.userToDo.group_types.value.length > 0) {
                  viewMessage.userToDo.group_types.value.forEach((element) => {
                    if (element.groups) {
                      if (element.groups.value) {
                        element.groups.value = Object.keys(
                          element.groups.value
                        ).map((key2) => ({
                          ...element.groups.value[key2],
                          id: key2,
                        }));
                      }
                    }
                  });
                }
              } else {
                viewMessage.userToDo.group_types.value = [];
              }
            } else {
              viewMessage.userToDo.group_types = {};
              viewMessage.userToDo.group_types.value = [];
            }
          } else {
            viewMessage.userToDo = {};
          }
          if (viewMessage.userToDo) {
            if (viewMessage.userToDo.group_types) {
              if (viewMessage.userToDo.group_types.lastSelectedGroupType) {
                viewMessage.userToDo.group_types.value.find((gt) => {
                  if (
                    gt.id ===
                    viewMessage.userToDo.group_types.lastSelectedGroupType
                  ) {
                    if (gt.groups) {
                      if (gt.groups.lastSelectedGroup && gt.groups.value) {
                        gt.groups.value.find((g) => {
                          if (g.id === gt.groups.lastSelectedGroup) {
                            this.selectedGroup = g;
                          }
                        });
                      }
                    }
                  }
                });
              }
            }
          }
          viewMessage.loading_tmp = false;
        }
      });
      setTimeout(() => {
        if (!this.userToDo) {
          fire
            .database()
            .ref(`todo/users/${this.UID}`)
            .update({ uid: this.UID });
        }
      }, 1000);
      await this.loadRecords();
    }
  },
  methods: {
    async loadRecords() {
      let viewMessage = this;
      let itemsRef = await fire
        .database()
        .ref(`todo/users/${this.UID}/records/`);
      await itemsRef.on("value", async (snapshot) => {
        let data = snapshot.val();
        if (data) {
          viewMessage.allRecords = Object.keys(data).map((key) => {
            let arr = {
              ...data[key],
              id: key,
            };
            if (arr.groups) {
              arr.groups = Object.keys(arr.groups).map((key) => ({
                ...arr.groups[key],
                id: key,
              }));
            }
            return arr;
          });
        }
      });
    },
    async groupSelected(group) {
      if (group) {
        if (this.userToDo) {
          if (this.userToDo.group_types) {
            if (this.userToDo.group_types.lastSelectedGroupType && group) {
              await fire
                .database()
                .ref(
                  `todo/users/${this.UID}/group_types/value/${this.userToDo.group_types.lastSelectedGroupType}/groups/`
                )
                .update({
                  lastSelectedGroup: group.id,
                });
            }
            this.selectedGroup = group;
          }
        }
      }
    },
    async groupTypeSelected(id) {
      if (this.userToDo) {
        if (this.userToDo.group_types) {
          if (this.userToDo.group_types.lastSelectedGroupType !== id) {
            await fire
              .database()
              .ref(`todo/users/${this.UID}/group_types/`)
              .update({ lastSelectedGroupType: id });
          }
        }
      }
    },
  },
  components: {
    groupTypes,
    records,
  },
};
</script>
